/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostOrderInstance } from '../models/PostOrderInstance';
import type { SiteVisitOperationGrouped } from '../models/SiteVisitOperationGrouped';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SiteVisitOperationService {

    /**
     * Ack Post Order
     * @param shiftId
     * @param date
     * @param index
     * @param customerId
     * @param taskId
     * @returns PostOrderInstance Successful Response
     * @throws ApiError
     */
    public static ackPostOrderApiV1AssignmentAckPostOrderShiftIdDateIndexPut(
        shiftId: string,
        date: string,
        index: number,
        customerId?: (string | null),
        taskId?: (string | null),
    ): CancelablePromise<PostOrderInstance> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/assignment/ack_post_order/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'customer_id': customerId,
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Site Visit Operation For Assignment Customer Id And Task Id
     * @param customerId
     * @param id
     * @param taskId
     * @returns SiteVisitOperationGrouped Successful Response
     * @throws ApiError
     */
    public static readSiteVisitOperationForAssignmentCustomerIdAndTaskIdApiV1EmployeeAssignmentIdSiteCustomerIdGet(
        customerId: string,
        id: string,
        taskId: string,
    ): CancelablePromise<SiteVisitOperationGrouped> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee/assignment/{id}/site/{customer_id}',
            path: {
                'customer_id': customerId,
                'id': id,
            },
            query: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
