import React from 'react'
import { Select } from '@mui/material'

import { Controller, UseControllerProps, FieldValues } from 'react-hook-form'

type SelectProps = {
    name: string
    requiredSelect?: boolean
    multiSelect?: boolean
    defaultValue?: any
    fullWidth?: boolean
    readOnly?: boolean
    children: React.ReactNode
    size?: 'small' | 'medium'
}

type ControlledSelectProps<T extends FieldValues> = SelectProps &
    UseControllerProps<T>

export const ControlledSelect = <T extends FieldValues>({
    name,
    control,
    requiredSelect,
    multiSelect,
    defaultValue,
    children,
    fullWidth,
    size = 'medium',
    readOnly,
}: ControlledSelectProps<T>) => {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: requiredSelect ?? false,
                    message: `Missing required field: ${name}`,
                },
            }}
            render={({ field: { onChange, value } }) => (
                <Select
                    onChange={onChange}
                    value={value}
                    multiple={multiSelect}
                    fullWidth={!!fullWidth}
                    size={size}
                    readOnly={!!readOnly}
                    disabled={!!readOnly}
                >
                    {children}
                </Select>
            )}
        />
    )
}
