import { FormControl, FormLabel, FormHelperText } from '@mui/material'

type FormControlledFieldProps = {
    error?: string
    label: string | JSX.Element
    requiredField?: boolean
    children: JSX.Element
}

export const FormControlledField = ({
    error,
    label,
    requiredField,
    children,
}: FormControlledFieldProps) => {
    return (
        <FormControl fullWidth error={Boolean(error)}>
            <FormLabel required={requiredField}>{label}</FormLabel>
            {children}
            <FormHelperText style={{ color: '#d32f2f' }}>
                {error}
            </FormHelperText>
        </FormControl>
    )
}
