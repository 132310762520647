import React from 'react'
import { Box } from '@mui/material'
import { DetailTabs } from '../DetailTabs'
import { DetailsTab } from '../../types/detailsTab'
import { withRole } from '../withRole'

type LayoutProps = React.PropsWithChildren<{}>

const TabbedCustomerParentsLayoutAdmin = ({ children }: LayoutProps) => {
    const tabLinks: DetailsTab[] = [
        {
            name: 'Sites',
            link: `/customers/sites`,
            linkRoot: `/customers/sites`,
        },
        {
            name: 'Parents',
            link: `/customers/parents`,
            linkRoot: `/customers/parents`,
        },
    ]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <DetailTabs data={tabLinks} />
            <main>{children}</main>
        </Box>
    )
}

const TabbedCustomerParentsLayoutBasic = ({ children }: LayoutProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <main>{children}</main>
        </Box>
    )
}

export const TabbedCustomerParentsLayout = withRole(['Admin'])(
    TabbedCustomerParentsLayoutAdmin,
    TabbedCustomerParentsLayoutBasic
)
