import React from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import { parseISO, format, isValid } from 'date-fns'

type ISODatePicker = {
    control: any
    name: string
    defaultValue?: string | null
    disabled?: boolean
    allowNull?: boolean
    minDate?: Date
    maxDate?: Date
    inputProps?: {}
}

export const ControlledDatePicker = ({
    control,
    name,
    defaultValue,
    disabled,
    allowNull,
    minDate,
    maxDate,
    inputProps,
}: ISODatePicker) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                validate: {
                    required: (value) => {
                        if (maxDate && parseISO(value) > maxDate) {
                            return 'Maximum post end date is: 2026-1-1'
                        }

                        return allowNull ? true : isValid(parseISO(value))
                    },
                },
            }}
            render={({ field: { onChange, value, ref } }) => {
                return (
                    <DatePicker
                        value={defaultValue ? parseISO(value) : null}
                        onChange={(date: Date | null) => {
                            if (date && isValid(date)) {
                                onChange(format(date, 'yyyy-MM-dd'))
                            } else {
                                onChange(date)
                            }
                        }}
                        disabled={disabled}
                        format="yyyy-MM-dd"
                        slotProps={{
                            textField: {
                                InputProps: inputProps,
                                required: !allowNull,
                            },
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                )
            }}
            defaultValue={defaultValue}
        />
    )
}
