/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomReport } from '../models/CustomReport';
import type { CustomReportCreate } from '../models/CustomReportCreate';
import type { CustomReportUpdate } from '../models/CustomReportUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomReportService {

    /**
     * Read Custom Reports
     * @returns CustomReport Successful Response
     * @throws ApiError
     */
    public static readCustomReportsApiV1CustomReportsGet(): CancelablePromise<Array<CustomReport>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/custom-reports',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Custom Report
     * @param id
     * @returns CustomReport Successful Response
     * @throws ApiError
     */
    public static readCustomReportApiV1CustomReportsIdGet(
        id: string,
    ): CancelablePromise<CustomReport> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/custom-reports/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Custom Report
     * @param id
     * @param requestBody
     * @returns CustomReport Successful Response
     * @throws ApiError
     */
    public static updateCustomReportApiV1CustomReportsIdPut(
        id: string,
        requestBody: CustomReportUpdate,
    ): CancelablePromise<CustomReport> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/custom-reports/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Custom Report
     * @param id
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteCustomReportApiV1CustomReportsIdDelete(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/custom-reports/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Custom Report
     * @param shiftInstanceId
     * @param requestBody
     * @param customerId
     * @param siteVisitOperationId
     * @param runsheetTaskId
     * @param customReportTemplateId
     * @returns CustomReport Successful Response
     * @throws ApiError
     */
    public static createCustomReportApiV1CustomReportsShiftInstanceShiftInstanceIdPost(
        shiftInstanceId: string,
        requestBody: CustomReportCreate,
        customerId?: (string | null),
        siteVisitOperationId?: (string | null),
        runsheetTaskId?: (string | null),
        customReportTemplateId?: (string | null),
    ): CancelablePromise<CustomReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/custom-reports/shift_instance/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            query: {
                'customer_id': customerId,
                'site_visit_operation_id': siteVisitOperationId,
                'runsheet_task_id': runsheetTaskId,
                'custom_report_template_id': customReportTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Adhoc Custom Report
     * @param customerId
     * @param requestBody
     * @param shiftInstanceId
     * @param siteVisitOperationId
     * @param runsheetTaskId
     * @param customReportTemplateId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createAdhocCustomReportApiV1CustomReportsCustomerCustomerIdPost(
        customerId: string,
        requestBody: CustomReportCreate,
        shiftInstanceId?: (string | null),
        siteVisitOperationId?: (string | null),
        runsheetTaskId?: (string | null),
        customReportTemplateId?: (string | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/custom-reports/customer/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'shift_instance_id': shiftInstanceId,
                'site_visit_operation_id': siteVisitOperationId,
                'runsheet_task_id': runsheetTaskId,
                'custom_report_template_id': customReportTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
