import { useEffect, useState } from 'react'

export function useMineralSso(
    username: string | undefined | boolean
): string | undefined {
    const [url, setUrl] = useState<string | undefined>()

    useEffect(() => {
        if (!!username) {
            getMineralSso(username as string).then((data) => {
                setUrl(data)
            })
        }
    }, [username])

    return url
}

async function getMineralSso(username: string) {
    // THIS ONLY WORKS WITH NGROK + LOCAL OR PROD BECAUSE OF REFERRER RESTRICTION
    return fetch(
        `https://${process.env.NEXT_PUBLIC_MINERAL_REST_DOMAIN}/v2/oauth/sso?authCode=${process.env.NEXT_PUBLIC_MINERAL_AUTH_CODE}&clientId=${process.env.NEXT_PUBLIC_MINERAL_CLIENT_ID}&SSOID=${process.env.NEXT_PUBLIC_MINERAL_PARTNER_NAME}:${username}`,
        {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                Authorization: window.btoa(
                    `${process.env.NEXT_PUBLIC_MINERAL_CLIENT_ID}:${process.env.NEXT_PUBLIC_MINERAL_CLIENT_SECRET}`
                ),
            },
        }
    )
        .then((res) => res.json())
        .then((res) => {
            return res.sso_url
        })
}
