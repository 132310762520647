import React from 'react'
import { Loading } from '../Loading'
import { Grid, Stack, Typography, Button, styled } from '@mui/material'
import { useLoadUser } from '../../services/user'
import { useAuth0 } from '@auth0/auth0-react'
import Image from 'next/image'
import { OFFICER_ROLE_ID } from '../../utils/auth'
import { INTERNAL_USERS } from '../../constants/previewEligibleOrgs'

const Centering = styled('div')({
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
})

type BelfryAuthWrapperProps = React.PropsWithChildren<{}>

type Auth0EnabledWindow = Window & { zE: (arg0: string, ag1: string) => void }
function windowHasAuth0(window: Window): window is Auth0EnabledWindow {
    return typeof (window as Auth0EnabledWindow).zE === 'function'
}

export const InvalidUser = () => {
    const { logout } = useAuth0()

    const logOutUser = () => {
        if (window && windowHasAuth0(window)) {
            window.zE('messenger', 'logoutUser')
        }
        logout({
            returnTo: window.location.origin,
        })
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Stack>
                    <Centering>
                        <Image
                            src="/Belfry_Logo_Indigo.svg"
                            alt="Belfry"
                            width={200}
                            height={60}
                        />
                    </Centering>
                    <Typography>
                        Error logging into Belfry, please contact your manager
                        to confirm you have been added to this account.
                    </Typography>
                    <Button onClick={() => logOutUser()}>Logout</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

export const OfficerUser = () => {
    const { logout } = useAuth0()

    const logOutUser = () => {
        if (window && windowHasAuth0(window)) {
            window.zE('messenger', 'logoutUser')
        }
        logout({
            returnTo: window.location.origin,
        })
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3}>
                <Stack>
                    <Centering>
                        <Image
                            src="/Belfry_Logo_Indigo.svg"
                            alt="Belfry"
                            width={200}
                            height={60}
                        />
                    </Centering>
                    <Typography>
                        Error logging into Belfry, please contact your manager
                        to confirm you have been assigned a role of supervisor
                        or above to log in.
                    </Typography>
                    <Button onClick={() => logOutUser()}>Logout</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

export const BelfryAuthWrapper = ({
    children,
    ...props
}: BelfryAuthWrapperProps) => {
    const { user: auth0User } = useAuth0()
    const { user, isLoading, isError } = useLoadUser()

    const internalAccount =
        auth0User && auth0User.sub && INTERNAL_USERS.includes(auth0User.sub)
    const invalidAccount =
        (!user || !user.officer_id || isError) && !internalAccount
    const officerAccount =
        user && user.role_id == OFFICER_ROLE_ID && !internalAccount

    if (isLoading) {
        return <Loading />
    }

    if (invalidAccount) {
        return <InvalidUser />
    }

    if (officerAccount) {
        return <OfficerUser />
    }

    return <>{children}</>
}
