import useSWR from 'swr'
import { toast } from 'react-toastify'
import { errorReasonToString } from '../utils/errorUtils'
import { FunctionStatusType } from './sharedTypes'
import router from 'next/router'
import {
    PayGroup,
    PayGroupCreate,
    PayGroupWithAssignments,
    OfficerPayGroupStatus,
    PayGroupService,
    BasicOfficer,
    ApiError,
} from '../generated'

type CreatePayGroupCreateFunc = (payGroup: PayGroupCreate) => Promise<PayGroup>
export const createPayGroup: CreatePayGroupCreateFunc = (payGroup) => {
    return PayGroupService.createPayGroupsApiV1PayGroupsPut(payGroup)
}

type LoadPayGroupType = {
    payGroup: PayGroupWithAssignments | undefined
} & FunctionStatusType
type LoadPayGroupFunc = (payGroupId: string) => LoadPayGroupType
export const useLoadPayGroup: LoadPayGroupFunc = (payGroupId) => {
    const { data, error } = useSWR([`pay_groups`, payGroupId], () =>
        PayGroupService.readPayGroupApiV1PayGroupPayGroupIdGet(payGroupId)
    )
    return {
        payGroup: data,
        isLoading: !error && !data,
        isError: error,
    }
}
type LoadWPayGroupForWorkerType = {
    payGroups: PayGroup[]
} & FunctionStatusType
type LoadPayGroupsForWorkerFunc = (
    workerId: string
) => LoadWPayGroupForWorkerType
export const useLoadPayGroupsForWorker: LoadPayGroupsForWorkerFunc = (
    workerId
) => {
    const { data, error } = useSWR([`pay_groups_worker`, workerId], () =>
        PayGroupService.readPayGroupsForOfficerApiV1PayGroupsWorkerOfficerIdGet(
            workerId
        )
    )
    return {
        payGroups: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}
type LoadDefaultPayGroupType = {
    defaultPayGroupEmployees: BasicOfficer[]
} & FunctionStatusType
type LoadDefaultPayGroupFunc = () => LoadDefaultPayGroupType
export const useLoadDefaultPayGroup: LoadDefaultPayGroupFunc = () => {
    const { data, error } = useSWR([`pay_groups_default`], () =>
        PayGroupService.readDefaultPayGroupApiV1PayGroupDefaultGet()
    )
    return {
        defaultPayGroupEmployees: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadPayGroupsType = {
    payGroups: PayGroup[]
} & FunctionStatusType

type LoadPayGroupsFunc = () => LoadPayGroupsType
export const useLoadPayGroups: LoadPayGroupsFunc = () => {
    const { data, error } = useSWR(
        `pay_groups`,
        PayGroupService.readPayGroupsApiV1PayGroupsGet
    )
    return {
        payGroups: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type ArchivePayGroupFunc = (
    payGroupId: string,
    endDate: string,
    mutate: any
) => Promise<void>
export const archivePayGroup: ArchivePayGroupFunc = async (
    payGroupId,
    endDate,
    mutate
) => {
    return mutate([`pay_groups`, payGroupId], () =>
        PayGroupService.archivePayGroupsApiV1PayGroupsPayGroupIdDelete(
            payGroupId,
            endDate
        )
    )
}

type DeletePayGroupFunc = (payGroupId: string, mutate: any) => Promise<void>
export const deletePayGroup: DeletePayGroupFunc = async (
    payGroupId,
    mutate
) => {
    return mutate(`pay_groups`, async (payGroups: PayGroup[]) => {
        return await PayGroupService.deletePayGroupApiV1PayGroupsPayGroupIdFullDeleteDelete(
            payGroupId
        )
            .then(() => {
                router.push('/settings/pay_groups')
                return payGroups.filter((payGroup) => payGroup.id != payGroupId)
            })
            .catch((error: ApiError) => {
                toast.error(errorReasonToString(error))
                return payGroups
            })
    })
}

type AddPayGroupEmployeeFunc = (
    employeeId: string,
    payGroupId: string,
    startDate: string,
    mutate: any
) => Promise<void>
export const addPayGroupEmployee: AddPayGroupEmployeeFunc = async (
    employeeId,
    payGroupId,
    startDate,
    mutate
) => {
    mutate(
        [`pay_groups`, payGroupId],
        async (payGroup: PayGroupWithAssignments) => {
            try {
                return await PayGroupService.addWorkerToPayGroupApiV1PayGroupMappingPost(
                    {
                        officer_id: employeeId,
                        pay_group_id: payGroupId,
                        start_date: startDate,
                    }
                ).then((newMapping) => {
                    payGroup.pay_group_mappings = [
                        newMapping,
                        ...payGroup.pay_group_mappings,
                    ]
                    toast.success('Employee Added to Pay Group')
                    return payGroup
                })
            } catch (error: any) {
                toast.error(errorReasonToString(error))
                return payGroup
            }
        }
    )
}

type DownloadPayGroupFile = () => Promise<void>
export const downloadPayGroupFile: DownloadPayGroupFile = async () => {
    await PayGroupService.getBulkPayGroupAddFileApiV1PayGroupMappingBulkAddEmployeesGet()
        .then((res) => {
            const blob = new Blob([res], {
                type: 'text/csv',
            })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `pay-group-upload-format.csv`
            alink.click()
        })
        .catch((reason) => toast.error(errorReasonToString(reason)))
}

type BulkAddPayGroupFunc = (
    file: File,
    payGroupId: string,
    mutate: any
) => Promise<void>
export const bulkAddPayGroup: BulkAddPayGroupFunc = async (
    file,
    payGroupId,
    mutate
) => {
    await PayGroupService.bulkPayGroupAddApiV1PayGroupMappingBulkAddPayGroupIdPost(
        payGroupId,
        { file: file }
    ).then((res) => {
        const blob = new Blob([res], {
            type: 'text/csv',
        })
        const fileURL = window.URL.createObjectURL(blob)
        let alink = document.createElement('a')
        alink.href = fileURL
        alink.download = `pay-group-upload-result.csv`
        alink.click()
        mutate([`pay_group_employees`, payGroupId], undefined, true)
    })
}

type RemovePayGroupEmployeeFunc = (
    endDate: string,
    payGroupId: string,
    employeeId: string,
    mutate: any
) => Promise<void>
export const removePayGroupEmployee: RemovePayGroupEmployeeFunc = async (
    endDate,
    payGroupId,
    employeeId,
    mutate
) => {
    mutate(
        [`pay_groups`, payGroupId],
        async (payGroup: PayGroupWithAssignments) => {
            await PayGroupService.archiveWorkerFromPayGroupApiV1PayGroupMappingPayGroupIdOfficerIdDelete(
                payGroupId,
                employeeId,
                endDate
            ).catch((reason) => toast.error(errorReasonToString(reason)))

            payGroup.pay_group_mappings = payGroup.pay_group_mappings.filter(
                (payGroupMapping) =>
                    !(
                        payGroupMapping.officer_id == employeeId &&
                        payGroupMapping.pay_group_id == payGroupId
                    )
            )
            return payGroup
        }
    )
}

type LoadEmployeePayGroupDetailsType = {
    OfficerPayGroupStatus: OfficerPayGroupStatus | undefined
} & FunctionStatusType
type LoadEmployeePayGroupFunc = (
    employeeId: string | null
) => LoadEmployeePayGroupDetailsType
export const useEmployeeLoadPayGroupDetails: LoadEmployeePayGroupFunc = (
    employeeId
) => {
    const { data, error } = useSWR(
        employeeId ? [`employee_pay_groups`, employeeId] : null,
        () =>
            PayGroupService.getPayGroupMappingsForOfficerApiV1PayGroupStatusOfficerIdGet(
                employeeId!
            )
    )
    return {
        OfficerPayGroupStatus: data,
        isLoading: !error && !data,
        isError: error,
    }
}
