import { parseISO, isValid, differenceInMinutes } from 'date-fns'

import {
    BreakInstance,
    ScheduleShiftInstance,
    Settings,
    ShiftInstanceForSchedule,
    TimeOff,
} from '../../generated'
import {
    maybeAbbrevTZIfDiffers,
    parseAndFormatWithTZAndTimeDisplaySetting,
} from '../../utils/dateUtils'
import { sumArrayOfObjects } from '../../utils/arr'

export function computeActualBreakCount(break_instances: BreakInstance[]) {
    return break_instances.filter(
        (instance) => instance.started && instance.ended
    ).length
}

export function getTimeHelper(
    ts: string | undefined,
    iana_timezone: string,
    use24HourClock?: boolean
) {
    if (!ts || !isValid(parseISO(ts))) {
        return 'N/A'
    }
    return (
        parseAndFormatWithTZAndTimeDisplaySetting(
            ts,
            iana_timezone,
            'h:mma',
            'HH:mm',
            use24HourClock ?? false
        ).toLowerCase() + maybeAbbrevTZIfDiffers(iana_timezone)
    )
}

export function getScheduledShiftHourTotal(
    shift_instance: ShiftInstanceForSchedule | ScheduleShiftInstance
) {
    const hours = shift_instance.scheduled_shift_details?.total_hours
    return typeof hours !== 'number'
        ? 'N/A'
        : `${hours.toLocaleString(undefined, {
              minimumFractionDigits: 0,
          })}h`
}

export function getActualShiftHourTotal(
    shift_instance: ShiftInstanceForSchedule | ScheduleShiftInstance
) {
    const hours = shift_instance.actual_shift_details?.total_hours
    if (hours === 0) {
        return '0h'
    }
    return typeof hours !== 'number'
        ? 'N/A'
        : `${hours.toLocaleString(undefined, {
              minimumFractionDigits: 0,
          })}h`
}
export const SUMMARY_KEYS_LABELS_SUPERVISOR = [
    { label: 'regular', key: 'regular_hours', denom: 1 },
    { label: 'ot', key: 'total_overtime_hours', denom: 1 },
    { label: '2ot', key: 'double_overtime_compensation_hours', denom: 1 },
    { label: 'hol', key: 'holiday_hours', denom: 1 },
    { label: 'pto', key: 'pto_hours', denom: 1 },
    { label: 'sick', key: 'sick_hours', denom: 1 },
    { label: 'total', key: 'total_hours', denom: 1, prefix: '' },
]

export const SUMMARY_KEYS_LABELS_NON_SUPERVISOR = [
    ...SUMMARY_KEYS_LABELS_SUPERVISOR,
    { label: 'comp', key: 'total_compensation', denom: 100, prefix: '$' },
]

function sumTimeOffForPolicyType(
    timeOffs: TimeOff[],
    policyType: 'PTO' | 'SICK' | 'UNPAID'
) {
    return (
        timeOffs
            .filter((timeOff) => timeOff.policy_type == policyType)
            .reduce((acc, val) => acc + val.minutes_for_time_off, 0) / 60
    )
}

export function preProcessTotalsHelper(
    shift_instances: ScheduleShiftInstance[],
    timeOffs: TimeOff[]
) {
    const scheduledTotals = sumArrayOfObjects(
        shift_instances.map((inst) => inst.scheduled_shift_details)
    )
    const scheduledBreaks = scheduledTotals['number_breaks']
    // There is no count of OT hours without double OT hours, so we have to dynamically calc it.
    scheduledTotals['total_overtime_hours'] =
        scheduledTotals['total_overtime_hours'] -
        scheduledTotals['double_overtime_compensation_hours']
    const actualTotals = sumArrayOfObjects(
        shift_instances
            .filter((inst) => inst.actual_shift_details)
            .map((inst) => inst.actual_shift_details)
    )

    const actualBreaks = actualTotals['number_breaks']
    actualTotals['total_overtime_hours'] =
        actualTotals['total_overtime_hours'] -
        actualTotals['double_overtime_compensation_hours']

    // Update totals based on time offs
    scheduledTotals['pto_hours'] = sumTimeOffForPolicyType(timeOffs, 'PTO')
    scheduledTotals['sick_hours'] = sumTimeOffForPolicyType(timeOffs, 'SICK')
    scheduledTotals['unpaid'] = sumTimeOffForPolicyType(timeOffs, 'UNPAID')
    const timeOffTotalComp = timeOffs
        .filter(
            (timeOff) =>
                timeOff.policy_type == 'SICK' || timeOff.policy_type == 'PTO'
        )
        .reduce((acc, val) => acc + val.total_time_off_comp, 0)

    scheduledTotals['total_hours'] =
        scheduledTotals['total_hours'] == undefined ||
        Number.isNaN(scheduledTotals['total_hours'])
            ? scheduledTotals['pto_hours'] + scheduledTotals['sick_hours']
            : scheduledTotals['total_hours'] +
              scheduledTotals['pto_hours'] +
              scheduledTotals['sick_hours']
    scheduledTotals['total_compensation'] = scheduledTotals[
        'total_compensation'
    ]
        ? scheduledTotals['total_compensation'] + timeOffTotalComp
        : timeOffTotalComp

    actualTotals['pto_hours'] = sumTimeOffForPolicyType(timeOffs, 'PTO')
    actualTotals['sick_hours'] = sumTimeOffForPolicyType(timeOffs, 'SICK')
    actualTotals['unpaid'] = sumTimeOffForPolicyType(timeOffs, 'UNPAID')

    actualTotals['total_hours'] =
        actualTotals['total_hours'] == undefined ||
        Number.isNaN(actualTotals['total_hours'])
            ? actualTotals['pto_hours'] + actualTotals['sick_hours']
            : actualTotals['total_hours'] +
              actualTotals['pto_hours'] +
              actualTotals['sick_hours']

    actualTotals['total_compensation'] = actualTotals['total_compensation']
        ? actualTotals['total_compensation'] + timeOffTotalComp
        : timeOffTotalComp

    const breakDifference = Math.abs(actualBreaks - scheduledBreaks)
    const totalHourDifference = Math.abs(
        scheduledTotals['total_hours'] - actualTotals['total_hours']
    )
    return {
        breakDifference,
        totalHourDifference,
        scheduledTotals,
        actualTotals,
    }
}

export function checkhq_processing_period_days_to_int(
    checkhq_processing_period?: string
) {
    if (checkhq_processing_period === 'one_day') {
        return 1
    } else if (checkhq_processing_period === 'two_day') {
        return 2
    } else {
        return 4
    }
}

export function isSchedMismatchHelper(
    instance: ScheduleShiftInstance | ShiftInstanceForSchedule,
    settings: Settings
) {
    const [startDiff, endDiff] = getSchedVersusActualDiffs(instance)
    const tk_auto_approval_thresh_minutes =
        settings.tk_auto_approval_thresh_minutes || 1
    return (
        Math.abs(startDiff) > tk_auto_approval_thresh_minutes ||
        Math.abs(endDiff) > tk_auto_approval_thresh_minutes
    )
}

export function isClockInWithinThreshHelper(
    instance: ScheduleShiftInstance | ShiftInstanceForSchedule,
    settings: Settings
) {
    if (!instance.clock_in) {
        return false
    }
    const startDiff = differenceInMinutes(
        parseISO(instance.shift_start_date_time as string),
        parseISO(instance.clock_in)
    )
    const tk_auto_approval_thresh_minutes =
        settings.tk_auto_approval_thresh_minutes || 1
    return Math.abs(startDiff) < tk_auto_approval_thresh_minutes
}

function getSchedVersusActualDiffs(
    instance: ScheduleShiftInstance | ShiftInstanceForSchedule
) {
    if (
        !instance.clock_in ||
        !instance.clock_out ||
        !instance.shift_start_date_time ||
        !instance.shift_end_date_time
    ) {
        return [NaN, NaN]
    }
    const startDiff = differenceInMinutes(
        parseISO(instance.shift_start_date_time),
        parseISO(instance.clock_in)
    )
    const endDiff = differenceInMinutes(
        parseISO(instance.shift_end_date_time),
        parseISO(instance.clock_out)
    )
    return [startDiff, endDiff]
}
