import useSWR from 'swr'

import {
    AuditRecordParentCustomer,
    ParentCustomer,
    ParentCustomerCreate,
    ParentCustomerService,
    ParentCustomerUpdate,
} from '../generated'
import { FunctionStatusType } from './sharedTypes'
import { toast } from 'react-toastify'

type LoadParentCustomersType = {
    parentCustomers: ParentCustomer[]
} & FunctionStatusType

type LoadParentCustomerType = {
    parentCustomer: ParentCustomer | undefined
} & FunctionStatusType

type LoadCustomersFunc = (onlyArchived?: boolean) => LoadParentCustomersType
export const useLoadParentCustomers: LoadCustomersFunc = (
    onlyArchived = false
) => {
    const { data, error } = useSWR([`/parent_customers`, onlyArchived], () =>
        ParentCustomerService.readParentCustomersApiV2ParentCustomersGet(
            onlyArchived
        )
    )
    return {
        parentCustomers: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadParentCustomerFunc = (id: string) => LoadParentCustomerType
export const useLoadParentCustomer: LoadParentCustomerFunc = (id) => {
    const { data, error } = useSWR(['parent-customer', id], () =>
        ParentCustomerService.readParentCustomerApiV2ParentCustomerIdGet(id)
    )
    return {
        parentCustomer: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type createParentCustomerCallFunc = (
    parentCustomer: ParentCustomerCreate
) => Promise<ParentCustomer>
export const createParentCustomerCall: createParentCustomerCallFunc = (
    parentCustomer
) => {
    return ParentCustomerService.createParentCustomerApiV2ParentCustomerPost(
        parentCustomer
    )
}

type UpdateParentCustomerFunc = (
    id: string,
    parentCustomer: ParentCustomerUpdate,
    mutate: any
) => any
export const updateParentCustomer: UpdateParentCustomerFunc = async (
    id,
    parentCustomer,
    mutate
) => {
    const options = { optimisticData: parentCustomer, rollbackOnError: true }
    mutate(
        ['parent-customer', id],
        () =>
            ParentCustomerService.updateParentCustomerApiV2ParentCustomerIdPut(
                id,
                parentCustomer
            ).catch((reason) =>
                toast.error(reason?.body?.detail || reason?.message)
            ),
        options
    )
}

type LoadParentCustomerAuditRecordType = {
    parent: AuditRecordParentCustomer | undefined
    isLoading: boolean
    isError: boolean
}
type LoadParentCustomerAuditRecordFunc = (
    parentId: string
) => LoadParentCustomerAuditRecordType
export const useLoadParentCustomerAuditRecord: LoadParentCustomerAuditRecordFunc =
    (parentId) => {
        const { data, error } = useSWR(`/parentcustomers/${parentId}`, () =>
            ParentCustomerService.readPostOrderAuditRecordApiV2ParentCustomerAuditRecordIdGet(
                parentId
            )
        )

        return {
            parent: data,
            isLoading: !data && !error,
            isError: error,
        }
    }
