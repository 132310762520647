import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'

const TaskIconContainer = styled('div')({
    color: 'white',
    background: '#029A11',
    borderWidth: '10px',
    borderRadius: '50%',
    padding: '3px',
    height: '26px',
})

export const CompleteTaskIcon = () => {
    return (
        <TaskIconContainer>
            <CheckIcon fontSize="small" />
        </TaskIconContainer>
    )
}
