import { memo } from 'react'

type ToolTipListProps = {
    title: string
    childTitles?: JSX.Element[] | string[] | undefined
}
export const ToolTipList = memo(({ title, childTitles }: ToolTipListProps) => {
    return (
        <>
            <div>{title}</div>
            {childTitles?.map((childTitle, idx) => {
                return (
                    <div key={idx}>
                        <span style={{ paddingLeft: '10px' }}>
                            • {childTitle}
                        </span>
                    </div>
                )
            })}
        </>
    )
})

ToolTipList.displayName = 'ToolTipList'
