import { useCallback, useMemo, useState } from 'react'
import { SideNavMenuLink } from './SideNavMenuLink'
import { NavMenuLink } from './navOptions'
import { Box, Collapse, Divider, List } from '@mui/material'
import Image from 'next/image'

function getFullPaths(navOption: NavMenuLink) {
    return navOption.paramName
        ? [
              `${navOption.path}/[[...${navOption.paramName}]]`,
              `${navOption.path}/[${navOption.paramName}]`,
          ]
        : [navOption.path]
}

function routerPathMatchesNavOption(
    routerPath: string,
    navOption: NavMenuLink
) {
    if (navOption.linkToFirstChild) {
        return false //If the parent is just acting as a link to a child, it should never be considered active
    }
    const directMatch = getFullPaths(navOption).includes(routerPath)
    if (directMatch) {
        return true
    }

    const matchesAdditional = navOption.additionalPaths?.includes(routerPath)

    if (matchesAdditional) {
        return true
    }

    return false
}

type SideNavMenuProps = {
    navOptions: NavMenuLink[]
    activePath: string
    expanded: boolean
}
export const SideNavMenu = ({
    navOptions,
    activePath,
    expanded,
}: SideNavMenuProps) => {
    // The parent path of the currently active route needs a little accent mark. Construct this map from paths to indexes of top-level options
    // so that we can easily lookup with section is active based on the activePath
    const sectionsByPath: Map<string, number> = useMemo(() => {
        const pathMap = new Map<string, number>()
        function addPath(path: string, sectionIdx: number): void {
            if (pathMap.has(path)) {
                // Only the first section to claim a path will be considered the active section for that path
                return
            }
            pathMap.set(path, sectionIdx)
        }
        navOptions.forEach((section, sectionIdx) => {
            getFullPaths(section).forEach((path) => addPath(path, sectionIdx))
            section.children?.forEach((link) => {
                addPath(link.path, sectionIdx)
                link.additionalPaths?.forEach((path) =>
                    addPath(path, sectionIdx)
                )
            })
        })

        return pathMap
    }, [navOptions])

    const activeSectionIdx = useMemo<number | undefined>(() => {
        return sectionsByPath.get(activePath)
    }, [sectionsByPath, activePath])

    const [hoveredSectionIdx, setHoveredSectionIdx] = useState<number | null>(
        null
    )
    const [expandedSection, setExpandedSection] = useState<number | null>(null)

    const handleCollapseChange = useCallback(
        function (open: boolean, idx: number) {
            if (idx === activeSectionIdx) return // Ignore. Always keep the active section open
            setExpandedSection(open ? idx : null)
        },
        [setExpandedSection, activeSectionIdx]
    )

    return (
        <>
            <Box
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                {navOptions.map((section, sectionIdx) => {
                    const sectionOpen =
                        expandedSection === sectionIdx ||
                        sectionIdx === activeSectionIdx
                    return (
                        <List
                            key={section.title}
                            component="div"
                            disablePadding
                            onMouseEnter={() =>
                                setHoveredSectionIdx(sectionIdx)
                            }
                            onMouseLeave={() => setHoveredSectionIdx(null)}
                        >
                            <SideNavMenuLink
                                href={section.href || section.path || '/'}
                                icon={section.icon}
                                title={section.title}
                                childTitles={
                                    section.children &&
                                    section.children.map(({ title }) => title)
                                }
                                disableTooltip={expanded}
                                accented={
                                    hoveredSectionIdx === sectionIdx ||
                                    activeSectionIdx === sectionIdx
                                }
                                highlighted={
                                    expanded &&
                                    routerPathMatchesNavOption(
                                        activePath,
                                        section
                                    )
                                }
                                collapseOpen={expanded && sectionOpen}
                                onCollapseChange={
                                    section.children?.length
                                        ? (isCollapsed) =>
                                              handleCollapseChange(
                                                  isCollapsed,
                                                  sectionIdx
                                              )
                                        : undefined
                                }
                            />
                            <Collapse
                                in={expanded && sectionOpen}
                                timeout="auto"
                            >
                                <List component="div" disablePadding>
                                    {section.children &&
                                        section.children.map((link) => {
                                            return (
                                                <SideNavMenuLink
                                                    key={link.title}
                                                    href={
                                                        link.href ||
                                                        link.path ||
                                                        '/'
                                                    }
                                                    title={link.title}
                                                    highlighted={
                                                        expanded &&
                                                        routerPathMatchesNavOption(
                                                            activePath,
                                                            link
                                                        )
                                                    }
                                                />
                                            )
                                        })}
                                </List>
                            </Collapse>
                        </List>
                    )
                })}
            </Box>

            <Divider />
            <Box
                justifyContent={'center'}
                display={'flex'}
                justifySelf={'flex-end'}
                m={2}
            >
                <Image
                    src="/logo.svg"
                    alt="Belfry"
                    width={17}
                    height={23}
                    style={{ margin: 4 }}
                />
                {expanded && (
                    <Image
                        src="/BELFRY.svg"
                        alt="Belfry"
                        width={69}
                        height={23}
                        style={{ margin: 4 }}
                    />
                )}
            </Box>
        </>
    )
}
