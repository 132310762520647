import React from 'react'
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import { ArchiveModal } from '../Customer/ArchiveModal'
import { Customer } from '../../generated'

const CustomerArchiveButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
})

type CustomerArchiveButtonProps = {
    customer: Customer | undefined
    id: string
}
export function CustomerArchiveButton({
    customer,
    id,
}: CustomerArchiveButtonProps) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <CustomerArchiveButtonContainer>
            <Button variant="text" color="primary" onClick={handleOpen}>
                Archive Customer
            </Button>
            {customer && (
                <ArchiveModal
                    open={open}
                    handleClose={handleClose}
                    customerName={customer.name}
                    customerId={id}
                />
            )}
        </CustomerArchiveButtonContainer>
    )
}
