import {
    styled,
    TextField,
    Button,
    Grid,
    Typography,
    ButtonGroup,
    FormLabel,
    FormControl,
    MenuItem,
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ControlledDatePicker } from '../../Date'
import { format } from 'date-fns'
import {
    terminateOfficer,
    useLoadOfficerEarliestTerminationDate,
} from '../../../services/officer'
import { Loading } from '../../Loading'
import { useSWRConfig } from 'swr'
import { ApiError, FirmAddon, TerminateOfficer } from '../../../generated'
import { useRouter } from 'next/router'
import { ContentModal } from '../../Modal'
import { ControlledSelect } from '../../ControlledSelect'
import { FormControlledField } from '../../Form'
import { withFirmAddon } from '../../withRole/withFirmAddon'
import { detailedLinkErrorToToast } from '../../Payroll/detailedLinkErrorToToast'
import { CustomTooltip } from 'src/components/EmployeeForms/shared'

// Why is this here?
// So the backend API tells us the earliest termination date for this employee in YYYY-MM-DD format
// But MUI DatePicker interprerts this as an ISO string with Midnight UTC timezone, which when converted to
// our timezone (behind UTC) actually shows a minimum date = expected minimum date - 1
// So we simply add our local offset to UTC to the Date variable which ensures the interpreted date is as expected
const createAdjustedDate = (dateString: string) => {
    // Create a date at midnight UTC
    const date = new Date(dateString + 'T00:00:00Z')

    // Get the local timezone offset in milliseconds
    const offset = date.getTimezoneOffset() * 60000

    // Adjust the date by subtracting the timezone offset
    const adjustedDate = new Date(date.getTime() + offset)

    return adjustedDate
}

const FullWidthFormControl = styled(FormControl)({
    width: '100%',
})

type TerminateModalProps = {
    open: boolean
    handleClose: () => void
    officerName: string | undefined
    officerId: string
    officerIsPayrollDisabled: boolean
}

const FinalPaycheckInput = ({ control }: { control: any }) => {
    return (
        <>
            <Grid item>
                <FormControlledField
                    requiredField
                    label="Pay Final Paycheck"
                    error={undefined}
                >
                    <ControlledSelect
                        name={'termination_final_paycheck_is_off_cycle'}
                        control={control}
                        defaultValue={false}
                    >
                        <MenuItem key={'false'} value={false as any}>
                            As part of normal pay schedule
                        </MenuItem>
                        <MenuItem key={'true'} value={true as any}>
                            Create off-cycle payroll now
                        </MenuItem>
                    </ControlledSelect>
                </FormControlledField>
            </Grid>
        </>
    )
}

const PayrollAddonGatedFinalPaycheckComp = withFirmAddon(
    FirmAddon.addon_kind.CHECKHQ_PAYROLL
)(FinalPaycheckInput)

export const TerminateModal = ({
    open,
    handleClose,
    officerName,
    officerId,
    officerIsPayrollDisabled,
}: TerminateModalProps) => {
    const { mutate } = useSWRConfig()
    const router = useRouter()
    const {
        officer: earliestTerminationOfficer,
        isLoading,
        isError,
    } = useLoadOfficerEarliestTerminationDate(officerId)
    const { register, handleSubmit, control, watch } =
        useForm<TerminateOfficer>()
    const [submitting, isSubmitted] = React.useState<boolean>(false)
    const terminateOfficerCallback = React.useCallback(
        (terminate: TerminateOfficer) => {
            isSubmitted(true)
            return terminateOfficer(officerId, terminate)
                .then((value: any) => {
                    mutate('/officer')
                    mutate(`/officer/${officerId}`)
                    if (terminate.termination_final_paycheck_is_off_cycle) {
                        router.push({
                            query: { id: officerId, termination_step: 2 },
                        })
                    } else {
                        router.push('/employees')
                    }
                })
                .catch((error: ApiError) => {
                    detailedLinkErrorToToast(error as any)
                    isSubmitted(false)
                    handleClose()
                })
        },
        []
    )
    const submitButtonText = watch('termination_final_paycheck_is_off_cycle')
        ? 'Terminate & Set Up Final Payroll'
        : 'Terminate Worker'

    // If given a date  for earliest termination, use it, else use today's
    const latestTerminationDate = new Date()
    const parsedEarliestTerminationDate =
        earliestTerminationOfficer?.date !== undefined
            ? createAdjustedDate(earliestTerminationOfficer.date)
            : undefined
    const actualEarliestTerminationDate =
        parsedEarliestTerminationDate != undefined &&
        parsedEarliestTerminationDate < latestTerminationDate
            ? parsedEarliestTerminationDate
            : latestTerminationDate
    const showEarliestTermReason =
        actualEarliestTerminationDate == parsedEarliestTerminationDate &&
        earliestTerminationOfficer?.reason

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            {submitting && <Loading />}
            {!submitting && (
                <form
                    onSubmit={handleSubmit((data) => {
                        terminateOfficerCallback(data)
                    })}
                >
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant="h2">
                                Terminate Worker
                            </Typography>
                            <Typography variant="body1">
                                This will remove {officerName} from your
                                organization and will un-assign them from all
                                future shifts.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FullWidthFormControl>
                                <CustomTooltip
                                    title="Termination Date"
                                    required
                                    popper={
                                        <div>
                                            <p>
                                                Enter the termination date for
                                                this worker.
                                            </p>
                                            {showEarliestTermReason ? (
                                                <p>
                                                    The earliest termination
                                                    date is{' '}
                                                    {
                                                        earliestTerminationOfficer?.date
                                                    }{' '}
                                                    because{' '}
                                                    {
                                                        earliestTerminationOfficer?.reason
                                                    }
                                                </p>
                                            ) : null}
                                        </div>
                                    }
                                />
                                <ControlledDatePicker
                                    control={control}
                                    minDate={actualEarliestTerminationDate}
                                    maxDate={latestTerminationDate}
                                    name={'termination_date'}
                                    defaultValue={format(
                                        latestTerminationDate,
                                        'yyyy-MM-dd'
                                    )}
                                />
                            </FullWidthFormControl>
                        </Grid>

                        {!officerIsPayrollDisabled ? (
                            <PayrollAddonGatedFinalPaycheckComp
                                control={control}
                            />
                        ) : null}

                        <Grid item>
                            <FormLabel required>Termination Reason</FormLabel>
                            <TextField
                                fullWidth
                                multiline
                                rows={5}
                                {...register(
                                    //@ts-ignore - fails to infer correct type from fieldName
                                    `termination_reason`,
                                    { required: true }
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item marginTop={3}>
                        <ButtonGroup fullWidth>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="error"
                                type="submit"
                            >
                                {submitButtonText}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </form>
            )}
        </ContentModal>
    )
}
