import { Chip, emphasize, styled } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import Link from 'next/link'
import React from 'react'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = theme.palette.secondary.main
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    }
}) as typeof Chip

type BreadcrumbProps = {
    path: string
    label: string
}
export function Breadcrumb({ path, label }: BreadcrumbProps) {
    return (
        <Link href={path} legacyBehavior>
            <StyledBreadcrumb
                component="a"
                href="#"
                label={label}
                icon={<NavigateBeforeIcon style={{ color: '#4338CA' }} />}
            />
        </Link>
    )
}
