import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { DetailTabs } from '../DetailTabs'
import { DetailsTab } from '../../types/detailsTab'
type LayoutProps = React.PropsWithChildren<{}>

export const TabbedChangelogLayout = ({ children }: LayoutProps) => {
    let tabLinks: DetailsTab[] = [
        {
            name: 'Payroll',
            link: `/analytics/changelog/payroll`,
            linkRoot: `/analytics/changelog/payroll`,
        },
        {
            name: 'HR',
            link: `/analytics/changelog/hr`,
            linkRoot: `/analytics/changelog/hr`,
        },
        {
            name: 'Notification',
            link: `/analytics/changelog/notification`,
            linkRoot: `/analytics/changelog/notification`,
        },
    ]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ padding: 1 }}>
                <Typography variant="h2">Changelog Reports</Typography>
            </Box>
            <Grid container>
                <Grid item xs={12}>
                    <DetailTabs data={tabLinks} variant={'fullWidth'} />
                </Grid>
            </Grid>
            <main>{children}</main>
        </Box>
    )
}
