import { Grid } from '@mui/material'
import React from 'react'
import { useRouter } from 'next/router'
import { validateCustomerId } from '../../services/customer'
import { InnerTabs } from '../InnerTabs/InnerTabs'
import { DetailsTab } from '../../types/detailsTab'

type LayoutProps = React.PropsWithChildren<{}>
export function TabbedReportsBelltowerLayout({ children }: LayoutProps) {
    const router = useRouter()
    const id = validateCustomerId(router.query)

    const linksData: DetailsTab[] = [
        {
            name: 'Reports',
            link: `/belltower/reports`,
            linkRoot: `/belltower/reports/reports`,
        },
        {
            name: 'Report Templates',
            link: `/belltower/report-templates`,
            linkRoot: `/belltower/reports/report-templates`,
            hideOnMobile: true,
        },
        {
            name: 'Lists',
            link: `/belltower/belfrylists`,
            linkRoot: `/belltower/reports/lists`,
        },
    ]
    return (
        <Grid container>
            <Grid container direction="row">
                <Grid item flexGrow={1}>
                    <Grid container flexDirection="row" gap={1}>
                        <InnerTabs data={linksData} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid width="100%">
                <main>{children}</main>
            </Grid>
        </Grid>
    )
}
