import React from 'react'
import { useRouter } from 'next/router'
import { useLoadOfficer, validateOfficerId } from '../../services/officer'
import {
    SmsErrorIndicator,
    TerminatedEmployeeIndicator,
} from '../Officer/Contact'
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { Role, useUserIsAdmin, useUserIsOfRole } from '../../utils/auth'
import { DetailsTab } from '../../types/detailsTab'
import {
    doesFirmHavePayrollAddonHelper,
    useLoadFirmWithAddons,
} from '../../services/firm'
import { PayrollOfficerStatusBadge } from '../Payroll/PayrollOfficerStatusBadge'
import { OfficerActionsButton } from '../OfficerActionsMenu/OfficerActionsMenu'
import { EmployeeHeader } from '../EmployeeHeader/EmployeeHeader'
import { EmployeePageTabs } from '../EmployeePage/EmployeePageTabs'
import NextLink from 'next/link'
import { OfficerStatus } from 'src/generated'
import { OfficerInactiveBadge } from '../Officer/Contact/OfficerInactiveBadge'
import { usePreviewEligible } from 'src/hooks'
import { Features } from 'src/constants/previewEligibleOrgs'

const TEMINATION_AND_PAYROLL_ENABLED_ROLES: Role[] = ['Admin', 'HR']
const PDF_EXPORT_ENABLED_ROLES: Role[] = ['Admin', 'Manager', 'HR']

// User can see officer PDF export if they are Admin , HR, Manager
// For managers, they can only see supervisor or officer's PDFs
const managerPDFHiddenRoleIDs = [
    'rol_7c58cr2QK5YbCaXF', // prod admin
    'rol_t5Yx3BjRrHXGEbco', // prod manager
    'rol_cDQ4VASa4jsSrby3', // prod HR
    'rol_ZvW8UI0UkYc1qCAB', // dev admin
    'rol_q9GlB2TbrwwFKeXA', // dev manager
    'rol_tieomIARFf21voZJ', // dev HR
]

type LayoutProps = React.PropsWithChildren<{}>
type TabbedOfficerLayoutProps = {
    hideMenuTabs?: boolean
} & LayoutProps
export const TabbedOfficerLayout = ({
    hideMenuTabs = false,
    children,
    ...props
}: TabbedOfficerLayoutProps) => {
    const router = useRouter()
    const id = validateOfficerId(router.query)
    const { officer } = useLoadOfficer(id)

    const { firm, isLoading } = useLoadFirmWithAddons()
    const isTerminationAndPayrollPrivileged = useUserIsOfRole(
        TEMINATION_AND_PAYROLL_ENABLED_ROLES
    )
    const isAuditLogPrivileged = useUserIsAdmin()

    const userIsOfPdfEnabledRole = useUserIsOfRole(PDF_EXPORT_ENABLED_ROLES)
    const userIsManagerBlockedFromPDFExport =
        useUserIsOfRole(['Manager']) &&
        managerPDFHiddenRoleIDs.includes(officer?.role_id as string)

    const isPDFExportPrivileged =
        userIsOfPdfEnabledRole && !userIsManagerBlockedFromPDFExport

    const shouldShowPayroll =
        isTerminationAndPayrollPrivileged &&
        firm &&
        doesFirmHavePayrollAddonHelper(firm) &&
        !officer?.is_payroll_disabled

    const shouldShowDocs = useUserIsOfRole(['Admin', 'HR', 'Manager'])
    const shouldShowTimeOff = usePreviewEligible(Features.EMPLOYEE_TIMEOFF)

    let tabLinks: DetailsTab[] = [
        {
            name: 'Overview',
            link: `/employees/${id}/details`,
            linkRoot: `/employees/${id}/details`,
        },
        {
            name: 'Employment',
            link: `/employees/${id}/employment`,
            linkRoot: `/employees/${id}/employment`,
        },
        {
            name: 'Schedule',
            link: `/employees/${id}/schedule`,
            linkRoot: `/employees/${id}/schedule`,
        },
        {
            name: 'Timekeeping',
            link: `/employees/${id}/timekeeping`,
            linkRoot: `/employees/${id}/timekeeping`,
        },
        {
            name: 'Certifications',
            link: `/employees/${id}/certifications`,
            linkRoot: `/employees/${id}/certifications`,
        },
        {
            name: 'Notes & Call-Offs',
            link: `/employees/${id}/notes`,
            linkRoot: `/employees/${id}/notes`,
        },
        {
            name: 'Performance',
            link: `/employees/${id}/performance`,
            linkRoot: `/employees/${id}/performance`,
        },
    ]

    if (shouldShowPayroll) {
        const link = {
            name: 'Payroll',
            link: `/employees/${id}/payroll`,
            linkRoot: `/employees/${id}/payroll`,
        }
        tabLinks = [...tabLinks.slice(0, 2), link, ...tabLinks.slice(2)]
    }

    if (shouldShowDocs) {
        const link = {
            name: 'Documents',
            link: `/employees/${id}/documents`,
            linkRoot: `/employees/${id}/documents`,
        }
        tabLinks = [...tabLinks, link]
    }

    if (shouldShowTimeOff) {
        const link = {
            name: 'Time Off',
            link: `/employees/${id}/timeoff`,
            linkRoot: `/employees/${id}/timeoff`,
        }
        tabLinks = [...tabLinks, link]
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    mb: 4,
                }}
                mb={4}
                gap={2}
            >
                {hideMenuTabs ? (
                    <Box sx={{ padding: 1, '&:hover': { cursor: 'pointer' } }}>
                        <NextLink href={`/employees/${id}/details`}>
                            <Typography
                                variant="body2"
                                fontWeight={500}
                                color="#253A8E"
                            >
                                {'< Back'}
                            </Typography>
                        </NextLink>
                    </Box>
                ) : (
                    <Box sx={{ padding: 1, '&:hover': { cursor: 'pointer' } }}>
                        <NextLink href={`/employees/`} legacyBehavior>
                            <Typography
                                variant="body2"
                                fontWeight={500}
                                color="#253A8E"
                            >
                                {'< Back'}
                            </Typography>
                        </NextLink>
                    </Box>
                )}
                <Box sx={{ padding: 1, marginBottom: 2 }}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={9} display="flex" alignItems="center">
                            {officer && (
                                <EmployeeHeader
                                    userImageUrl={officer.image_url}
                                    avatarSize={140}
                                    name={officer.name}
                                    enableImageUpload
                                    officerId={id}
                                    phoneNumber={officer.phone_number}
                                    email={officer.email}
                                    address={officer.postal_address}
                                    badges={
                                        <>
                                            <SmsErrorIndicator
                                                officer={officer}
                                                fontSize="large"
                                            />
                                            <PayrollOfficerStatusBadge
                                                officer={officer}
                                            />
                                            {officer.is_terminated && (
                                                <TerminatedEmployeeIndicator />
                                            )}
                                            {officer.status ===
                                            OfficerStatus.INACTIVE ? (
                                                <OfficerInactiveBadge />
                                            ) : null}
                                        </>
                                    }
                                />
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {officer ? (
                                <OfficerActionsButton
                                    officer={officer}
                                    id={id}
                                    isTerminationAndPayrollPrivileged={
                                        isTerminationAndPayrollPrivileged
                                    }
                                    firm={firm}
                                    isAuditLogPrivileged={isAuditLogPrivileged}
                                    isPDFExportPrivileged={
                                        isPDFExportPrivileged
                                    }
                                />
                            ) : (
                                <Skeleton />
                            )}
                        </Grid>
                    </Grid>
                </Box>
                {hideMenuTabs ? null : <EmployeePageTabs data={tabLinks} />}
                {children}
            </Box>
        </>
    )
}
