import React from 'react'

import { useLoadFirmWithAddons } from '../../services/firm'
import { FirmAddon, FirmWithAddons } from '../../generated'

// A higher order component, similar to withRole, but takes a single string representing a firm addon,
// the only one as of June 2023 is FirmAddon.addon_kind.CHECKHQ_PAYROLL

/*eslint-disable react/display-name  */
export const withFirmAddon =
    (requiredAddon: string) =>
    (Component: any, FallbackComponent?: any) =>
    (props: any) => {
        const { firm } = useLoadFirmWithAddons()
        if (doesFirmHaveAddonHelper(requiredAddon, firm)) {
            return <Component {...props} />
        } else if (FallbackComponent) {
            return <FallbackComponent {...props} />
        } else {
            return null
        }
    }
/*eslint-enable react/display-name  */

function doesFirmHaveAddonHelper(addonKind: string, firm?: FirmWithAddons) {
    if (!firm || !firm.addons || !firm.addons.length) {
        return false
    }
    return (
        firm.addons.findIndex(
            (addon: any) => addon.addon_kind === addonKind
        ) !== -1
    )
}

type EmployeeOnboardingEnabledFuncType = {
    enabled: boolean
}
type EmployeeOnboardingEnabledType = () => EmployeeOnboardingEnabledFuncType
export const useEmployeeOnboardingIsEnabled: EmployeeOnboardingEnabledType =
    () => {
        const { firm } = useLoadFirmWithAddons()
        return {
            enabled:
                firm !== undefined &&
                doesFirmHaveAddonHelper(
                    FirmAddon.addon_kind.EMPLOYEE_ONBOARDING,
                    firm
                ),
        }
    }

type PayrollEnabledFuncType = {
    enabled: boolean
}
type PayrollEnabledType = () => PayrollEnabledFuncType
export const usePayrollIsEnabled: PayrollEnabledType = () => {
    const { firm } = useLoadFirmWithAddons()
    return {
        enabled:
            firm !== undefined &&
            doesFirmHaveAddonHelper(FirmAddon.addon_kind.CHECKHQ_PAYROLL, firm),
    }
}
