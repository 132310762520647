import { List, ListItem, Tooltip, styled } from '@mui/material'
import { ToolTipList } from './ToolTipList'

const TightListItem = styled(ListItem)({
    display: 'list-item',
    padding: 0,
    paddingBottom: '2px',
    margin: 0,
})
const StyledList = styled(List)({
    listStyleType: 'disc',
    padding: 8,
})

type TruncatedListProps = {
    limit: number
    title: string
    items: JSX.Element[] | string[]
}
export const TruncatedList = ({
    title,
    limit = 5,
    items,
}: TruncatedListProps) => {
    const childrenToDisplay = items.slice(0, limit)
    const numAdditional = Math.max(items.length - limit, 0)
    const additionalChildren = items.slice(limit)

    return (
        <StyledList>
            {childrenToDisplay.map((child, i) => {
                return <TightListItem key={i}>{child}</TightListItem>
            })}
            <Tooltip
                title={
                    <ToolTipList
                        title={title}
                        childTitles={additionalChildren}
                    ></ToolTipList>
                }
            >
                {numAdditional > 0 ? (
                    <TightListItem sx={{ cursor: 'pointer' }}>
                        + {numAdditional} more
                    </TightListItem>
                ) : (
                    <></>
                )}
            </Tooltip>
        </StyledList>
    )
}
