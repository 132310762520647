import useSWR from 'swr'
import { RegionService, Region, RegionUpdate, RegionCreate } from '../generated'
import { FunctionStatusType } from './sharedTypes'
import useSWRImmutable from 'swr/immutable'

type LoadRegionsType = {
    regions: Region[]
} & FunctionStatusType

type LoadRegionsFunc = () => LoadRegionsType
export const useLoadRegions: LoadRegionsFunc = () => {
    const { data, error } = useSWR(
        `/region`,
        RegionService.readRegionsApiV1RegionGet
    )
    return {
        regions: data ? (Array.isArray(data) ? data : [data]) : [],
        isLoading: !error && !data,
        isError: error,
    }
}

type UpdateRegionFunc = (
    region: RegionUpdate,
    regionId: string,
    mutate: any
) => void
export const updateRegion: UpdateRegionFunc = (region, regionId, mutate) => {
    mutate(`/region`, () =>
        RegionService.updateRegionApiV1RegionIdPut(regionId, region)
    )
}

type CreateRegionFunc = (region: RegionCreate, mutate: any) => void
export const createRegion: CreateRegionFunc = (region, mutate) => {
    mutate(`/region`, () => RegionService.createRegionApiV1RegionPost(region))
}

type DeleteRegionFunc = (regionID: string, mutate: any) => void
export const deleteRegion: DeleteRegionFunc = (regionID, mutate) => {
    mutate(`/region`, () =>
        RegionService.deleteRegionApiV1RegionIdDelete(regionID)
    )
}

type CurrentUserRegions = () => LoadRegionsType
export const useCurrentUserRegions: CurrentUserRegions = () => {
    const { data, error } = useSWRImmutable(
        `/region/user`,
        RegionService.readUserRegionsApiV1RegionUserGet
    )
    return {
        regions: data ? (Array.isArray(data) ? data : [data]) : [],
        isLoading: !error && !data,
        isError: error,
    }
}
