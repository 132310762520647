import { useAuth0 } from '@auth0/auth0-react'

const ROLES_URL = 'http://belfry.roles.com/roles'
const BELFRY_INTERNAL_ORG = ['org_7Q6oD3pzP6GL2LyR', 'org_chAXcStjxla3RcLJ']

const prodAdminRoleId = 'rol_7c58cr2QK5YbCaXF'
const prodManagerRoleId = 'rol_t5Yx3BjRrHXGEbco'
const prodSupervisorRoleId = 'rol_LPpBI1VxO3Z46Abs'
const prodHRRoleId = 'rol_cDQ4VASa4jsSrby3'
const prodOfficerRoleId = 'rol_ItZALN1F0rIHaUfO'

const devAdminRoleId = 'rol_ZvW8UI0UkYc1qCAB'
const devManagerRoleId = 'rol_q9GlB2TbrwwFKeXA'
const devSupervisorRoleId = 'rol_jfpXgV4CJSo7b0GR'
const devHRRoleId = 'rol_tieomIARFf21voZJ'
const devOfficerRoleId = 'rol_mLzDDY8kCFK7eikB'

const dev = process.env.NODE_ENV !== 'production'

export const OFFICER_ROLE_ID = dev ? devOfficerRoleId : prodOfficerRoleId

export const ADMIN_ROLE = 'Admin'
export const HR_ROLE = 'HR'
export const MANAGER_ROLE = 'Manager'
export const SUPERVISOR_ROLE = 'Supervisor'
const roleRanks = [ADMIN_ROLE, HR_ROLE, MANAGER_ROLE, SUPERVISOR_ROLE]

export const PAYROLL_ROLES = [ADMIN_ROLE, HR_ROLE]

export type Role =
    | typeof ADMIN_ROLE
    | typeof HR_ROLE
    | typeof MANAGER_ROLE
    | typeof SUPERVISOR_ROLE

// Map of which roles are above which roles
// Answers the question: "what roles can't I view."
interface RoleHierarchy {
    [key: string]: string[]
    Admin: string[]
    HR: string[]
    Manager: string[]
    Supervisor: string[]
}

const roleHierarchy: RoleHierarchy = {
    Admin: [],
    HR: dev ? [devAdminRoleId, devHRRoleId] : [devAdminRoleId, devHRRoleId],
    Manager: dev
        ? [devAdminRoleId, devManagerRoleId, devHRRoleId]
        : [prodAdminRoleId, prodManagerRoleId, prodHRRoleId],
    Supervisor: dev
        ? [devAdminRoleId, devManagerRoleId, devSupervisorRoleId, devHRRoleId]
        : [
              prodAdminRoleId,
              prodManagerRoleId,
              prodSupervisorRoleId,
              prodHRRoleId,
          ],
}

export const useRolesOfUser = () => {
    const { user } = useAuth0()
    return user ? user[ROLES_URL] : undefined
}

export const useMaxRoleOfUser = () => {
    const { user } = useAuth0()
    const userRole = user ? user[ROLES_URL] : undefined
    return roleRanks.find((roleRank) => userRole.includes(roleRank))
}

export const useOrgOfUser = () => {
    const { user } = useAuth0()
    return user ? user['org_id'] : undefined
}

export const useInternalUser = () => {
    const orgId = useOrgOfUser()
    return orgId ? BELFRY_INTERNAL_ORG.includes(orgId) : false
}

export const useUserIsAdmin = () => {
    const { user } = useAuth0()

    return user ? user[ROLES_URL].includes('Admin') : false
}

export const useUserIsPayrollPrivileged = () => {
    const { user } = useAuth0()

    return user
        ? user[ROLES_URL].includes('Admin') || user[ROLES_URL].includes('HR')
        : false
}

export const useUserIsOfRole = (roleNames: Role[]): boolean => {
    const { user } = useAuth0()

    return user
        ? user[ROLES_URL].filter((role: Role) => roleNames.includes(role))
              .length > 0
        : false
}

export const useUserIsFinancePrivileged = () => {
    const { user } = useAuth0()

    return user
        ? user[ROLES_URL].includes('Admin') ||
              user[ROLES_URL].includes('Manager') ||
              user[ROLES_URL].includes('HR')
        : false
}

export const useIsSubrole = (referenceRoleId: string | undefined): boolean => {
    const userRoles = useRolesOfUser()
    if (!userRoles) {
        return false
    }
    const maxRole = roleRanks.find((roleRank) => userRoles.includes(roleRank))
    return (
        !!referenceRoleId &&
        !!maxRole &&
        roleHierarchy[maxRole].includes(referenceRoleId)
    )
}

export const useGetOverRoles = (): string[] => {
    const userRoles = useRolesOfUser()
    const maxRole = roleRanks.find((roleRank) => userRoles.includes(roleRank))
    return maxRole && roleHierarchy[maxRole] ? roleHierarchy[maxRole] : []
}

/** Determines whether a provided role id is < the given role string */
export const roleIsOverReferenceRoleId = (
    role: string | undefined,
    referenceRoleId: string | undefined
) => {
    return (
        role === 'Admin' ||
        (!!referenceRoleId &&
            !!role &&
            !roleHierarchy[role].includes(referenceRoleId))
    )
}

export const useUserIsReferenceRolePaySettingsPrivileged = (
    referenceRoleId: string | undefined
): boolean => {
    const userIsFinancePrivileged = useUserIsFinancePrivileged()
    const userRoles = useRolesOfUser()
    if (!userIsFinancePrivileged) {
        return false
    }
    const maxRole = roleRanks.find((roleRank) => userRoles.includes(roleRank))
    return roleIsOverReferenceRoleId(maxRole, referenceRoleId)
}
