import React, { useState } from 'react'
import { Button, ButtonGroup, Grid, Typography } from '@mui/material'
import { ContentModal } from '../../Modal'
import { archiveCustomer } from '../../../services/customer'
import { ApiError } from '../../../generated'
import { useSWRConfig } from 'swr'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { Loading } from '../../Loading'

const loadingStyle = { height: '100%', minHeight: '100%' }

type ArchiveModalProps = {
    open: boolean
    handleClose: () => void
    customerName: string
    customerId: string
}

export const ArchiveModal = ({
    open,
    handleClose,
    customerName,
    customerId,
}: ArchiveModalProps) => {
    const { mutate } = useSWRConfig()
    const router = useRouter()
    const [submitting, setSubmitting] = useState(false)
    const handleArchiveCustomer = () => {
        setSubmitting(true)
        archiveCustomer(customerId, mutate)
            .then((res) => {
                router.push('/customers')
            })
            .catch((error: ApiError) => {
                toast.error(error?.message)
                setSubmitting(false)
            })
    }

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            {submitting && <Loading style={loadingStyle} />}
            {!submitting && (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h5">Archive Customer</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            This will archive {customerName} from your list of
                            customers.
                        </Typography>
                    </Grid>

                    <Grid item marginTop={3}>
                        <ButtonGroup fullWidth>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="error"
                                type="submit"
                                onClick={handleArchiveCustomer}
                            >
                                Archive Customer
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )}
        </ContentModal>
    )
}
