import {
    Box,
    FormLabel,
    Stack,
    Tooltip,
    Typography,
    styled,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'

export const SidebarText = styled(Stack)({
    color: '#253A8E',
})

const SidebarHintBox = styled(Box)({
    backgroundColor: '#FCFCFC',
    padding: '1em',
    display: 'flex',
})

type SidebarHintProps = {
    head: string
    description: string
}
export const SidebarHint = ({ head, description }: SidebarHintProps) => {
    return (
        <SidebarHintBox gap={1}>
            <div style={{ color: '#253A8E' }}>
                <HelpIcon />
            </div>
            <SidebarText spacing={2} style={{ whiteSpace: 'pre-line' }}>
                <Typography fontWeight={500}>{head}</Typography>
                <Typography fontWeight={400} fontSize={'0.9em'}>
                    {description}
                </Typography>
            </SidebarText>
        </SidebarHintBox>
    )
}

export const CustomErrorDisplay = styled('div')({
    color: 'red',
    fontSize: '0.8em',
    margin: '.5em',
    width: '100%',
})

export const GeneralInformationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em',
})

export const FieldRowContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    flexGrow: 1,
    width: '100%',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
})

export const TooltipContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
})

export const TooltipPopperContainer = styled('div')({
    color: '#FEFEFE',
    borderdRadius: '25px',
    padding: '0.5em',
    margin: 0,
    fontSize: '1.2em',
    display: 'inline-block',
})

export const PayrollDisabledTooltipPopper = (
    <TooltipPopperContainer>
        <Typography paragraph={true}>
            Turn this on if you won&apos;t be using Belfry&apos;s integrated
            payroll to pay this person.
        </Typography>
    </TooltipPopperContainer>
)

export const EEO1WorkerCategoryTooltipPopper = (
    <TooltipPopperContainer>
        <Typography paragraph={true}>
            {
                'Workforce data that may be required to report to the US Dept. of Labor'
            }
        </Typography>
    </TooltipPopperContainer>
)

type CustomTooltipProps = {
    title: string
    popper: JSX.Element
    required?: boolean
    icon?: JSX.Element
}
export const CustomTooltip = ({
    title,
    popper,
    required,
    icon,
}: CustomTooltipProps) => (
    <TooltipContainer>
        <FormLabel
            style={{
                color: '#191C1D99',
                fontSize: '0.99em',
                fontWeight: 450,
            }}
        >
            {title}
            {required ? (
                <span
                    aria-hidden="true"
                    className="MuiFormLabel-asterisk css-1qic9pp-MuiFormLabel-asterisk"
                >
                     *
                </span>
            ) : null}
        </FormLabel>
        <Tooltip title={popper} placement="right">
            {icon ?? <HelpIcon fontSize={'small'} />}
        </Tooltip>
    </TooltipContainer>
)
