/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IncidentCategoryMapResponse } from '../models/IncidentCategoryMapResponse';
import type { IncidentCategoryUpdate } from '../models/IncidentCategoryUpdate';
import type { IncidentTypeMapResponse } from '../models/IncidentTypeMapResponse';
import type { IncidentTypeUpdate } from '../models/IncidentTypeUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IncidentTypeService {

    /**
     * Get Custom Incident Type
     * Get the full incident type map JSON to send to the client.
     *
     * Raises:
     * HTTPException: If there is an error in the database query.
     * @returns IncidentTypeMapResponse Successful Response
     * @throws ApiError
     */
    public static getCustomIncidentTypeApiV1IncidentTypeCustomIncidentTypeGet(): CancelablePromise<IncidentTypeMapResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/incident_type/custom_incident_type',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Custom Incident Type
     * Add or update a custom incident type for the firm.
     *
     * Raises:
     * HTTPException: If the database query fails.
     * @param requestBody
     * @returns IncidentTypeMapResponse Successful Response
     * @throws ApiError
     */
    public static putCustomIncidentTypeApiV1IncidentTypeCustomIncidentTypePut(
        requestBody: IncidentTypeUpdate,
    ): CancelablePromise<IncidentTypeMapResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/incident_type/custom_incident_type',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Custom Incident Category
     * Get the full incident category map JSON to send to the client.
     *
     * Raises:
     * HTTPException: If the database query fails.
     * @returns IncidentCategoryMapResponse Successful Response
     * @throws ApiError
     */
    public static getCustomIncidentCategoryApiV1IncidentTypeCustomIncidentCategoryGet(): CancelablePromise<IncidentCategoryMapResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/incident_type/custom_incident_category',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Custom Incident Category
     * Add or update a custom incident category for the firm.
     *
     * Raises:
     * HTTPException: If the database query fails.
     * @param requestBody
     * @returns IncidentCategoryMapResponse Successful Response
     * @throws ApiError
     */
    public static putCustomIncidentCategoryApiV1IncidentTypeCustomIncidentCategoryPut(
        requestBody: IncidentCategoryUpdate,
    ): CancelablePromise<IncidentCategoryMapResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/incident_type/custom_incident_category',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
