/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AuditRecordOfficer } from './AuditRecordOfficer';
import type { AuditRelationshipRecord } from './AuditRelationshipRecord';

export type AuditRecord = {
    timestamp: string;
    user_email: string;
    action: AuditRecord.action;
    action_id: string;
    entity: AuditRecord.entity;
    entity_label: string;
    entity_id?: string;
    entity_name?: string;
    parent_entity?: string;
    parent_entity_label?: string;
    parent_entity_id?: string;
    associated_entity?: string;
    associated_entity_label?: string;
    associated_entity_id?: string;
    field?: string;
    field_label?: string;
    field_action?: AuditRecord.field_action;
    old_value?: (AuditRelationshipRecord | string | number | boolean);
    new_value?: (AuditRelationshipRecord | string | number | boolean);
    id: string;
    firm_id: string;
    user_id?: string;
    user?: AuditRecordOfficer;
};

export namespace AuditRecord {

    export enum action {
        CREATE = 'Create',
        UPDATE = 'Update',
        DELETE = 'Delete',
        ADD = 'Add',
        REMOVE = 'Remove',
    }

    export enum entity {
        OFFICER = 'Officer',
        NON_CUSTOMER_WORKPLACE = 'NonCustomerWorkplace',
        PREFERRED_SCHEDULE = 'PreferredSchedule',
        EMPLOYEE_NOTE = 'EmployeeNote',
        RATE_OVERRIDE = 'RateOverride',
        ISSUED_CERTIFICATION = 'IssuedCertification',
        ISSUED_LICENSE = 'IssuedLicense',
        OFFICER_BANNED_SITE = 'OfficerBannedSite',
        CUSTOMER = 'Customer',
        PARENT_CUSTOMER = 'ParentCustomer',
        CHILD_CUSTOMER_RELATIONSHIP = 'ChildCustomerRelationship',
        CONTACT = 'Contact',
        CONTRACT = 'Contract',
        AUX_BILLING_ITEM = 'AuxBillingItem',
        POST_ORDER = 'PostOrder',
        DUTY = 'Duty',
        CHECKPOINT = 'Checkpoint',
        CHECKPOINT_ACTION = 'CheckpointAction',
        SITE = 'Site',
        TOUR = 'Tour',
        TOUR_SCHEDULE = 'TourSchedule',
        PATROL_SITE_CONFIGURATION = 'PatrolSiteConfiguration',
        RUNSHEET_TEMPLATE = 'RunsheetTemplate',
        RUNSHEET = 'Runsheet',
        RUNSHEET_TASK = 'RunsheetTask',
        CONTRACT_BILLING_CONTACT_ASSOCIATION = 'ContractBillingContactAssociation',
        TOUR_CHECKPOINT_ASSOCIATION = 'TourCheckpointAssociation',
        HOLIDAY = 'Holiday',
        BREAK_ALLOTMENT = 'BreakAllotment',
        REGION = 'Region',
        SHIFT = 'Shift',
        SHIFT_INSTANCE = 'ShiftInstance',
        CALL_OFF = 'CallOff',
        TOUR_SHIFT_ASSOCIATION = 'TourShiftAssociation',
        OFFICER_DATE_MAPPING_ASSOCIATION = 'OfficerDateMappingAssociation',
        OFFICER_MAPPING_ASSOCIATION = 'OfficerMappingAssociation',
        DEFAULT_OFFICER_ASSOCIATION = 'DefaultOfficerAssociation',
        FIRM = 'Firm',
        SETTINGS = 'Settings',
        INVOICE = 'Invoice',
        PAYMENT_ACCOUNT = 'PaymentAccount',
        RUNSHEET_PATROL_SHIFT_ASSOCIATION = 'RunsheetPatrolShiftAssociation',
        DISTRIBUTION_SETTING = 'DistributionSetting',
        CUSTOM_REPORT_TEMPLATE = 'CustomReportTemplate',
        CONTACT_MAPPING_ASSOCIATION = 'ContactMappingAssociation',
        CONTACT_ASSOCIATION = 'ContactAssociation',
    }

    export enum field_action {
        CREATE = 'Create',
        UPDATE = 'Update',
        DELETE = 'Delete',
        ADD = 'Add',
        REMOVE = 'Remove',
    }


}

