import React, { createContext, useState } from 'react'
import { useIsMobileScreenSize } from 'src/hooks/useIsMobileScreenSize'

export const ShowMobileContext = createContext({
    shouldShowMobileView: false,
    setShouldShowMobileView: (b: boolean) => {},
    isMobileScreenSize: false,
})

type ShowMobileViewContextWrapperProps = React.PropsWithChildren<{}>

export const ShowMobileViewContextWrapper = ({
    children,
    ...props
}: ShowMobileViewContextWrapperProps) => {
    const isMobileScreenSize = useIsMobileScreenSize()
    const [shouldShowMobileView, setShouldShowMobileView] =
        useState(isMobileScreenSize)
    return (
        <ShowMobileContext.Provider
            // @ts-ignore
            value={{
                shouldShowMobileView,
                setShouldShowMobileView,
                isMobileScreenSize,
            }}
        >
            {children}
        </ShowMobileContext.Provider>
    )
}

export function WithShowDesktopOrMobileHOC<T, K>(
    DesktopComponent: React.ComponentType<T>,
    MobileComponent: React.ComponentType<K>
) {
    const CompHoc = (props: T & K) => {
        const { shouldShowMobileView } = React.useContext(ShowMobileContext)
        return !shouldShowMobileView ? (
            <DesktopComponent {...(props as JSX.IntrinsicAttributes & T)} />
        ) : (
            <MobileComponent {...(props as JSX.IntrinsicAttributes & K)} />
        )
    }
    return CompHoc
}
