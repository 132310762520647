import { Box, Typography, styled } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import NextLink from 'next/link'

const TaskListElementContainer = styled(Box)({
    width: '100%',
    border: '1px solid rgba(128, 128, 128, 0.2)',
    borderRadius: '6px',
    display: 'flex',
    height: '3.5em',
    justifyContent: 'space-around',
})

const TaskListActionableElementContainer = styled(TaskListElementContainer)({
    '&:hover': {
        cursor: 'pointer',
    },
})

const TaskListElementItem = styled(Box)({
    verticalAlign: 'middle',
    margin: 'auto',
})

export type TaskListElementProps = {
    iconEl: JSX.Element
    name: string
    href: string
    actionable: boolean
}
export const TaskListElement = ({
    iconEl,
    name,
    href,
    actionable,
}: TaskListElementProps) => {
    const taskListElementInner = (
        <>
            <TaskListElementItem flexBasis="10%" display="flex">
                <div style={{ margin: 'auto' }}>{iconEl}</div>
            </TaskListElementItem>

            <TaskListElementItem flexBasis="75%">
                <Typography variant="body2">{name}</Typography>
            </TaskListElementItem>

            <TaskListElementItem flexBasis="5%" display="flex" mt={'.5em'}>
                <div style={{ marginRight: 'auto', marginTop: '.5em' }}>
                    {actionable ? (
                        <ArrowForwardIosIcon fontSize="small" />
                    ) : (
                        <></>
                    )}
                </div>
            </TaskListElementItem>
        </>
    )

    return (
        <>
            {actionable ? (
                <NextLink href={href} legacyBehavior>
                    <TaskListActionableElementContainer>
                        {taskListElementInner}
                    </TaskListActionableElementContainer>
                </NextLink>
            ) : (
                <TaskListElementContainer>
                    {taskListElementInner}
                </TaskListElementContainer>
            )}
        </>
    )
}
