import React from 'react'

import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { DetailsTab } from '../../types/detailsTab'

function removeQueryString(url: string) {
    return url.split('?')[0]
}

export const EmployeePageTabs = ({
    data,
    variant = 'standard',
    extraTabEnd,
}: EmployeePageTabsProps) => {
    const router = useRouter()
    const activeTabIndex = data.findIndex((tab) =>
        tab ? removeQueryString(router.asPath).startsWith(tab.linkRoot) : false
    )
    // because we sometimes dynamically add tabs, this line prevents warnings from -1 not being found
    const activeTab = activeTabIndex === -1 ? 0 : activeTabIndex
    return (
        <Box
            sx={{
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderColor: '#9CA3AF',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <Tabs value={activeTab} variant={variant}>
                {data.map((tab, index) => {
                    if (!tab) return
                    const sx = index === activeTab ? { color: '#253A8E' } : {}
                    return (
                        <Link
                            href={tab.link}
                            passHref
                            key={tab.name}
                            legacyBehavior
                        >
                            <Tab
                                label={
                                    <Typography
                                        variant="body2"
                                        fontWeight={500}
                                        sx={sx}
                                    >
                                        {tab.name}
                                    </Typography>
                                }
                                value={index}
                                sx={{ textTransform: 'none' }}
                            />
                        </Link>
                    )
                })}
            </Tabs>
            {extraTabEnd && <Grid mb={1}>{extraTabEnd}</Grid>}
        </Box>
    )
}

type EmployeePageTabsProps = {
    data: (DetailsTab | null)[]
    variant?: 'fullWidth' | 'standard' | 'scrollable' | undefined
    extraTabEnd?: React.ReactNode
}
