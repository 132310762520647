import { Stack, Typography } from '@mui/material'
import { OnboardingAction } from '../../../src/generated'
import { TaskListElement, TaskListElementProps } from './TaskListElement'
import ArticleIcon from '@mui/icons-material/Article'
import { TaskIcon } from '../TaskIcon/TaskIcon'
import SubjectIcon from '@mui/icons-material/Subject'
import WorkIcon from '@mui/icons-material/Work'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import BadgeIcon from '@mui/icons-material/Badge'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { CompleteTaskIcon } from '../CompleteIcon/CompleteIcon'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'

export const getTaskListElementProps = (
    task: OnboardingAction
): TaskListElementProps => {
    if (task.document?.files) {
        return {
            name: `Acknowledge ${task.document.files[0].name}`,
            iconEl:
                task.status == OnboardingAction.status.COMPLETE ? (
                    <CompleteTaskIcon />
                ) : (
                    <TaskIcon iconEl={<ArticleIcon fontSize="small" />} />
                ),
            href: `/onboarding/action/${task.id}`,
        } as TaskListElementProps
    } else if (task.form_type == OnboardingAction.form_type.I9) {
        return {
            name: 'Fill out I-9 Form',
            iconEl:
                task.status == OnboardingAction.status.COMPLETE ? (
                    <CompleteTaskIcon />
                ) : (
                    <TaskIcon iconEl={<SubjectIcon fontSize="small" />} />
                ),
            href: `/onboarding/i9?id=${task.id}`,
        } as TaskListElementProps
    } else if (task.form_type == OnboardingAction.form_type.W4) {
        return {
            name: 'Fill out Payroll Form',
            iconEl:
                task.status == OnboardingAction.status.COMPLETE ? (
                    <CompleteTaskIcon />
                ) : (
                    <TaskIcon
                        iconEl={<AccountBalanceIcon fontSize="small" />}
                    />
                ),
            href: '/onboarding/payroll',
        } as TaskListElementProps
    } else if (task.form_type == OnboardingAction.form_type.WOTC) {
        return {
            name: 'Claim WOTC',
            iconEl:
                task.status == OnboardingAction.status.COMPLETE ? (
                    <CompleteTaskIcon />
                ) : (
                    <TaskIcon iconEl={<WorkIcon fontSize="small" />} />
                ),
            href: '/onboarding/wotc',
        } as TaskListElementProps
    } else if (task.form_type == OnboardingAction.form_type.PROFILE) {
        return {
            name: 'Complete your profile',
            iconEl:
                task.status == OnboardingAction.status.COMPLETE ? (
                    <CompleteTaskIcon />
                ) : (
                    <TaskIcon iconEl={<BadgeIcon fontSize="small" />} />
                ),
            href: '/onboarding/profile',
        } as TaskListElementProps
    } else if (task.form_type == OnboardingAction.form_type.AVAILABILITY) {
        return {
            name: 'Add your Preferred Availability',
            iconEl:
                task.status == OnboardingAction.status.COMPLETE ? (
                    <CompleteTaskIcon />
                ) : (
                    <TaskIcon iconEl={<EventNoteIcon fontSize="small" />} />
                ),
            href: `/onboarding/availability?id=${task.id}`,
        } as TaskListElementProps
    } else if (task.form_type == OnboardingAction.form_type.EEO1) {
        return {
            name: `Enter your demographic information`,
            iconEl:
                task.status == OnboardingAction.status.COMPLETE ? (
                    <CompleteTaskIcon />
                ) : (
                    <TaskIcon iconEl={<EmojiPeopleIcon fontSize="small" />} />
                ),
            href: `/onboarding/eeo1/${task.id}`,
        } as TaskListElementProps
    }
    return {
        name: 'Task',
        iconEl:
            task.status == OnboardingAction.status.COMPLETE ? (
                <CompleteTaskIcon />
            ) : (
                <TaskIcon iconEl={<ArticleIcon fontSize="small" />} />
            ),
        href: '/',
    } as TaskListElementProps
}

type TaskListProps = {
    header: string
    tasks?: OnboardingAction[]
    actionable: boolean
}
export const TaskList = ({ header, tasks, actionable }: TaskListProps) => {
    if (!tasks || !tasks.length) return null

    return (
        <Stack spacing={2}>
            <Typography variant="body2">{header}</Typography>

            {tasks ? (
                tasks.map((task: OnboardingAction) => (
                    <TaskListElement
                        {...getTaskListElementProps(task)}
                        key={task.id}
                        actionable={actionable}
                    />
                ))
            ) : (
                <br />
            )}
        </Stack>
    )
}
