import React from 'react'
import { Officer } from '../../../generated'
import Grid from '@mui/material/Grid'
import { Chip } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import Tooltip from '@mui/material/Tooltip'

export const SmsErrorIndicator = ({
    officer,
    fontSize = 'medium',
}: {
    officer: Officer | undefined
    fontSize?: any
}) => {
    if (!officer || !officer.sms_delivery_failure) {
        return null
    }

    return (
        <Grid item justifyContent="flex-end" display="flex" alignItems="center">
            <Tooltip title="SMS could not be delivered to this officer" arrow>
                <ErrorIcon color="error" fontSize={fontSize} />
            </Tooltip>
        </Grid>
    )
}

export const TerminatedEmployeeIndicator = () => {
    return (
        <Grid item justifyContent="flex-end" display="flex" alignItems="center">
            <Chip label={'Archived'} />
        </Grid>
    )
}
