import styled from '@emotion/styled'

const TaskIconContainer = styled('div')({
    color: '#253A8E',
    borderWidth: '10px',
    borderRadius: '50%',
    padding: '2px',
    height: '26px',
})

type TaskIconProps = {
    iconEl: JSX.Element
}
export const TaskIcon = ({ iconEl }: TaskIconProps) => {
    return (
        <TaskIconContainer>
            <div>{iconEl}</div>
        </TaskIconContainer>
    )
}
