import React from 'react'
import { Box, Button, Skeleton } from '@mui/material'
import NextLink from 'next/link'
import SettingsIcon from '@mui/icons-material/Settings'

import { DetailTabs } from '../DetailTabs'
import { useLoadPayGroups } from '../../services/pay_group'
import { useUserIsFinancePrivileged } from 'src/utils/auth'
type TimekeepingLayoutProps = {
    children: React.ReactNode
}

export const TabbedTimekeepingLayout = ({
    children,
}: TimekeepingLayoutProps) => {
    const { payGroups, isLoading } = useLoadPayGroups()
    const isUserFinancePriv = useUserIsFinancePrivileged()

    const payGroupTabs = payGroups.map((payGroup) => {
        return {
            name: `${payGroup.name} Pay Group`,
            link: `/timekeeping/period/${payGroup.id}`,
            linkRoot: `/timekeeping/period/${payGroup.id}`,
        }
    })

    const defaultPayGroupLabel =
        payGroups.length === 0 ? 'Pay Periods' : 'Default Pay Group'

    const tabData = [
        {
            name: 'Daily',
            link: '/timekeeping/day',
            linkRoot: '/timekeeping/day',
        },
    ]

    if (isUserFinancePriv) {
        tabData.push(
            {
                name: defaultPayGroupLabel,
                link: '/timekeeping/period/default',
                linkRoot: '/timekeeping/period/default',
            },
            ...payGroupTabs
        )
    }

    if (isLoading) {
        return <Skeleton />
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '75%' }}>
            <DetailTabs
                data={tabData}
                extraTabEnd={
                    <Box>
                        <NextLink
                            href="/settings/timekeeping"
                            passHref
                            legacyBehavior
                        >
                            <Button
                                variant="outlined"
                                startIcon={<SettingsIcon />}
                                sx={{ marginLeft: 1 }}
                            >
                                Settings
                            </Button>
                        </NextLink>
                    </Box>
                }
            />
            <main>{children}</main>
        </Box>
    )
}
