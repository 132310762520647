// import { ExpandLess, ExpandMore, Launch } from '@mui/icons-material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Launch from '@mui/icons-material/Launch'
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Tooltip,
    useTheme,
} from '@mui/material'
import Link from 'next/link'
import { SyntheticEvent, memo, useMemo } from 'react'
import { ToolTipList } from '../List'

const iconSpacerStyle = {
    minWidth: '41px',
    marginLeft: '14px',
    marginRight: '14px',
}

type SideNavMenuLinkProps = {
    title: string
    href: string
    icon?: JSX.Element | undefined
    highlighted?: boolean | undefined
    accented?: boolean | undefined
    childTitles?: string[] | undefined
    collapseOpen?: boolean | undefined
    onCollapseChange?: (open: boolean) => void | undefined
    disableTooltip?: boolean | undefined
}
export const SideNavMenuLink = memo(
    ({
        title,
        href,
        icon,
        highlighted,
        accented,
        collapseOpen,
        disableTooltip,
        childTitles,
        onCollapseChange,
    }: SideNavMenuLinkProps) => {
        const theme = useTheme()

        const accentVisibility = useMemo<'visible' | 'hidden'>(() => {
            return accented ? 'visible' : 'hidden'
        }, [accented])

        function handleCollapseHandleClick(
            event: SyntheticEvent,
            open: boolean
        ) {
            event.preventDefault()
            if (onCollapseChange) onCollapseChange(open)
        }
        const isExternal = useMemo<boolean>(() => href.includes('http'), [href])

        return (
            <Link href={href} passHref target={isExternal ? '_blank' : ''}>
                <ListItemButton
                    sx={{
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                        '& .MuiListItemText-primary': {
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                        paddingLeft: '8px',
                    }}
                    selected={highlighted}
                >
                    <Paper
                        square
                        sx={{
                            backgroundColor: theme.palette.nav.contrastText,
                            width: '4px',
                            minWidth: '4px',
                            minHeight: '20px',
                            height: '20px',
                            visibility: accentVisibility,
                        }}
                    ></Paper>

                    {icon ? (
                        <Tooltip
                            placement="right"
                            disableHoverListener={disableTooltip}
                            enterDelay={500}
                            leaveDelay={200}
                            arrow
                            title={
                                <ToolTipList
                                    title={title}
                                    childTitles={childTitles}
                                />
                            }
                        >
                            <ListItemIcon
                                sx={{
                                    ...iconSpacerStyle,
                                    color: theme.palette.nav.contrastText,
                                }}
                            >
                                {icon}
                            </ListItemIcon>
                        </Tooltip>
                    ) : (
                        <div style={iconSpacerStyle}></div>
                    )}
                    <ListItemText primary={title} />
                    {isExternal && <Launch />}

                    {onCollapseChange ? (
                        collapseOpen ? (
                            <ExpandLess
                                onClick={(e: SyntheticEvent) =>
                                    handleCollapseHandleClick(e, false)
                                }
                            />
                        ) : (
                            <ExpandMore
                                onClick={(e) =>
                                    handleCollapseHandleClick(e, true)
                                }
                            />
                        )
                    ) : (
                        ''
                    )}
                </ListItemButton>
            </Link>
        )
    }
)

SideNavMenuLink.displayName = 'SideNavMenuLink'
