import React from 'react'

import { Grid, Tooltip, Chip } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'

import {
    HighLevelOfficer,
    Officer,
    OfficerCategory,
    OfficerForTimekeepingView,
} from '../../generated'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DangerousIcon from '@mui/icons-material/Dangerous'
import { useGetSettings } from '../../services/settings'
import { withRole } from '../withRole'
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred'

function officerToPayrollStatusText(
    officer: HighLevelOfficer | Officer | OfficerForTimekeepingView
) {
    if (officer.is_payroll_disabled) {
        return 'Payroll has been disabled for this person'
    }
    const noun =
        officer.category === OfficerCategory.CONTRACTOR_1099_
            ? 'Contractor'
            : 'Employee'
    const isOnboardComplete =
        officer.checkhq_onboard_status === 'completed'
            ? ''
            : officer.checkhq_onboard_status === 'blocking'
            ? `${noun} payroll onboard is blocking`
            : officer.checkhq_onboard_status === 'needs_attention'
            ? `${noun} payroll onboard needs attention`
            : ''

    // disabling SSN for now as check seems to be misreporting it's validity
    //
    // const isSSNValid =
    //     officer.checkhq_ssn_val === 'validated'
    //         ? ''
    //         : officer.checkhq_ssn_val === 'invalid'
    //         ? 'SSN invalid'
    //         : 'SSN validation pending'

    // primary workplace is only really relevant for salaried workers
    const hasPrimaryWorkplaceOrIsHourly =
        officer.has_primary_workplace || !officer.salaried
            ? ''
            : 'Missing primary workplace'
    const reqs = [hasPrimaryWorkplaceOrIsHourly, isOnboardComplete]
    return reqs.filter((a) => a).join('. ')
}
type ColorType = OverridableStringUnion<
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
>

const STATUS_TO_UI_LOOKUP: { [key: string]: [string, ColorType, any] } = {
    blocking: ['Blocking', 'error', DangerousIcon],
    needs_attention: ['Needs Attention', 'warning', ErrorIcon],
    completed: ['Completed', 'success', CheckCircleIcon],
    disabled: ['Disabled', 'info', MoneyOffCsredIcon],
}

function getPayrollStatusUI(
    status: string | undefined,
    is_contractor: boolean
) {
    if (!status || !status.length) {
        // if we have no onboard status, it means we've never received an update event for this worker from Check in the payroll webhook
        // which means, for contractors, they haven't selected a type, and for employees means they haven't submitted a SSN or withholdings, which is now needs_attention
        // more info https://docs.checkhq.com/docs/onboard-status#onboard-status-for-employees
        return is_contractor
            ? STATUS_TO_UI_LOOKUP.blocking
            : STATUS_TO_UI_LOOKUP.needs_attention
    }
    if (STATUS_TO_UI_LOOKUP[status]) {
        return STATUS_TO_UI_LOOKUP[status]
    }
    // this case also shouldn't occur
    return [status, 'error', ErrorIcon]
}

const PayrollOfficerStatusBadgeInner = ({
    officer,
    isForListView,
}: {
    officer: HighLevelOfficer | Officer | OfficerForTimekeepingView
    isForListView: boolean
}) => {
    const { settings } = useGetSettings()
    const text = officerToPayrollStatusText(officer)
    const shouldShow = settings && settings.should_show_post_imp_payroll_ui
    if (!shouldShow) {
        return null
    }
    const checkhq_onboard_status = officer.is_payroll_disabled
        ? 'disabled'
        : officer.checkhq_onboard_status

    const is_contractor = officer.category === OfficerCategory.CONTRACTOR_1099_

    const [label, color, Icon] = getPayrollStatusUI(
        checkhq_onboard_status,
        is_contractor
    )
    const maybeUIProps = isForListView
        ? { variant: 'outlined', sx: { border: 'none' } }
        : {}

    return (
        <Grid item justifyContent="flex-end" display="flex" alignItems="center">
            <Tooltip title={text} arrow>
                {/* @ts-ignore */}
                <Chip
                    icon={<Icon />}
                    {...maybeUIProps}
                    label={
                        isForListView
                            ? (label as string)
                            : (`Payroll Onboard Status: ${label}` as string)
                    }
                    color={color as ColorType}
                />
            </Tooltip>
        </Grid>
    )
}

export const PayrollOfficerStatusBadge = withRole(['Admin', 'HR'])(
    PayrollOfficerStatusBadgeInner
)
