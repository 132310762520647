import MagicBell, {
    FloatingNotificationInbox,
} from '@magicbell/magicbell-react'
import { NotificationsNoneOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import React from 'react'
import { useLoadUser } from '../../services/user'

export function NotificationInboxWrapper() {
    const user = useLoadUser()

    if (!user.user || !user.user?.officer_id) {
        return <></>
    }

    return (
        <Box
            borderRadius={8}
            sx={{
                '&:hover': {
                    backgroundColor: '#C7D2FE',
                },
            }}
            padding={1}
            alignItems={'center'}
        >
            <MagicBell
                apiKey={process.env.NEXT_PUBLIC_MAGICBELL_PUBLIC_KEY}
                userExternalId={user.user?.officer_id}
                BellIcon={
                    <NotificationsNoneOutlined
                        sx={{
                            color: 'black',
                            fontSize: '24px',
                            borderRadius: 0,
                        }}
                    />
                }
                theme={{
                    header: {
                        fontFamily: 'Inter',
                    },
                    footer: {
                        fontFamily: 'Inter',
                    },
                    icon: {
                        borderColor: '#4338CA',
                    },
                    unseenBadge: {
                        fontSize: '12px',
                        fontFamily: 'Inter',
                    },
                    notification: {
                        unread: {
                            backgroundColor: '#F8F5FF',
                            backgroundOpacity: 1,
                            state: {
                                color: '#5225C1',
                            },
                            hover: {
                                backgroundColor: '#F2EDFC',
                            },
                            fontFamily: 'Inter',
                            title: {
                                fontFamily: 'Inter',
                            },
                        },
                        unseen: {
                            fontFamily: 'Inter',
                            title: {
                                fontFamily: 'Inter',
                            },
                        },
                        default: {
                            fontFamily: 'Inter',
                            title: {
                                fontFamily: 'Inter',
                            },
                        },
                    },
                }}
            >
                {(props) => (
                    <FloatingNotificationInbox
                        placement="bottom-start"
                        height={500}
                        {...props}
                    />
                )}
            </MagicBell>
        </Box>
    )
}
