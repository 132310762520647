import { MenuItem } from '@mui/material'
import { FormControlledField } from '../Form'
import { ControlledSelect } from '../ControlledSelect'
import { ControlledDatePicker } from '../Date'
import { format } from 'date-fns'

export function OffCyclePayrollOptionsAndPayDayInput({
    control,
    minDate,
}: {
    control: any
    minDate: Date
}) {
    return (
        <>
            <FormControlledField
                requiredField
                label="Payday Date"
                error={undefined}
            >
                <ControlledDatePicker
                    control={control}
                    name={'payday_date'}
                    minDate={minDate}
                    defaultValue={format(minDate, 'yyyy-MM-dd')}
                />
            </FormControlledField>

            <FormControlledField
                requiredField
                label="Force supplemental withholding?"
                error={undefined}
            >
                <ControlledSelect
                    name={'off_cycle_options.force_supplemental_withholding'}
                    control={control}
                    defaultValue={false}
                >
                    <MenuItem value="false">No</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                </ControlledSelect>
            </FormControlledField>

            <FormControlledField
                requiredField
                label="Apply benefits?"
                error={undefined}
            >
                <ControlledSelect
                    name={'off_cycle_options.apply_benefits'}
                    control={control}
                    defaultValue={false}
                >
                    <MenuItem value="false">No</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                </ControlledSelect>
            </FormControlledField>

            <FormControlledField
                requiredField
                label="Apply post tax deductions?"
                error={undefined}
            >
                <ControlledSelect
                    name={'off_cycle_options.apply_post_tax_deductions'}
                    control={control}
                    defaultValue={false}
                >
                    <MenuItem value="false">No</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                </ControlledSelect>
            </FormControlledField>
        </>
    )
}

export function convertOffCycleOptionStringsToBools(data: any) {
    data['force_supplemental_withholding'] =
        data['force_supplemental_withholding'] === 'true' ? true : false
    data['apply_benefits'] = data['apply_benefits'] === 'true' ? true : false
    data['apply_post_tax_deductions'] =
        data['apply_post_tax_deductions'] === 'true' ? true : false
    return data
}
