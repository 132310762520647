import useSWR, { KeyedMutator } from 'swr'

import {
    CustomerCreate,
    CustomerService,
    CustomerUpdate,
    Customer,
    ScheduleCustomer,
    HighLevelCustomer,
    File,
    FileCreate,
    FileUpdate,
    SiteCustomer,
} from '../generated'
import { errorReasonToString } from '../utils/errorUtils'
import { FunctionStatusType } from './sharedTypes'

import { ParsedUrlQuery } from 'querystring'
import { toast } from 'react-toastify'

export const validateCustomerId = (query: ParsedUrlQuery): string => {
    const { id } = query
    if (typeof id === 'string') {
        return id
    } else {
        return '' // TODO: @atulenko - improve error handling in UI layer
    }
}

type LoadCustomersType = {
    customers: HighLevelCustomer[]
} & FunctionStatusType

type LoadScheduleCustomersType = {
    customers: ScheduleCustomer[]
} & FunctionStatusType

type LoadSiteCustomersType = {
    customers: SiteCustomer[]
} & FunctionStatusType

type LoadCustomerType = {
    customer: Customer | undefined
    mutate: KeyedMutator<Customer>
} & FunctionStatusType

type LoadCustomerFilesType = {
    files: File[]
} & FunctionStatusType

type LoadCustomersFunc = (onlyArchived?: boolean) => LoadCustomersType
export const useLoadCustomers: LoadCustomersFunc = (onlyArchived = false) => {
    const { data, error } = useSWR([`/customers`, onlyArchived], () =>
        CustomerService.readCustomersApiV1CustomersGet(onlyArchived)
    )
    return {
        customers: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}
export const downloadCustomerExport = async (onlyArchived = false) => {
    await CustomerService.getCustomerExportApiV1CustomerExportGet(onlyArchived)
        .then((res) => {
            const blob = new Blob([res], {
                type: 'text/csv',
            })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `customer-status-export.csv`
            alink.click()
        })
        .catch((reason) => toast.error(errorReasonToString(reason)))
}

type LoadScheduleCustomersFunc = (
    onlyArchived?: boolean
) => LoadScheduleCustomersType
export const useLoadScheduleCustomers: LoadScheduleCustomersFunc = (
    onlyArchived = false
) => {
    const { data, error } = useSWR([`/customers/schedule`, onlyArchived], () =>
        CustomerService.readScheduleCustomersApiV1CustomersScheduleGet(
            onlyArchived
        )
    )
    return {
        customers: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadSiteCustomersFunc = (
    includeArchived?: boolean,
    patrolCustomerId?: string
) => LoadSiteCustomersType
export const useLoadSiteCustomersForPatrol: LoadSiteCustomersFunc = (
    includeArchived = false,
    patrolCustomerId
) => {
    const { data, error } = useSWR(`/customers/site`, () =>
        CustomerService.readSiteCustomersApiV1CustomersSiteGet(
            includeArchived,
            patrolCustomerId
        )
    )
    return {
        customers: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadCustomerFunc = (id: string) => LoadCustomerType
export const useLoadCustomer: LoadCustomerFunc = (id) => {
    const { data, error, mutate } = useSWR(`/customers/${id}`, () =>
        CustomerService.readCustomerApiV1CustomersIdGet(id)
    )
    return {
        customer: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

type createCustomerCallFunc = (customer: CustomerCreate) => Promise<Customer>
export const createCustomerCall: createCustomerCallFunc = (customer) => {
    return CustomerService.createCustomerApiV1CustomerPost(customer)
}

type UpdateCustomerFunc = (
    id: string,
    customer: CustomerUpdate,
    mutate: any
) => any
export const updateCustomer: UpdateCustomerFunc = async (
    id,
    customer,
    mutate
) => {
    const options = { optimisticData: customer, rollbackOnError: true }
    mutate(
        `/customers/${id}`,
        () =>
            CustomerService.updateCustomerApiV1CustomerIdPut(
                id,
                customer
            ).catch((reason) =>
                toast.error(reason?.body?.detail || reason?.message, {
                    autoClose: false,
                })
            ),
        options
    )
}

type ArchiveCustomerFunc = (id: string, mutate: any) => Promise<any>

export const archiveCustomer: ArchiveCustomerFunc = async (id, mutate) => {
    return mutate([`/customers`, false], async (customers: Customer[]) => {
        await CustomerService.archiveCustomerApiV1CustomerIdDelete(id)
        const filteredCustomer = customers.filter(
            (customer: Customer) => customer.id !== id
        )
        return [...filteredCustomer]
    })
}

type UnArchiveCustomerFunc = (id: string, mutate: any) => Promise<any>

export const unarchiveCustomer: UnArchiveCustomerFunc = async (id, mutate) => {
    return mutate(`/customers/${id}`, async () =>
        CustomerService.unarchiveCustomerApiV1CustomerUnarchiveIdPut(id)
    )
}

//  Customer file related functions
type LoadCustomerFilesFunc = (id: string) => LoadCustomerFilesType
export const useLoadCustomerFiles: LoadCustomerFilesFunc = (id: string) => {
    const { data, error } = useSWR(`/customer/${id}/files`, () =>
        CustomerService.getCustomerFilesApiV1CustomerIdFilesGet(id)
    )
    return {
        files: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type AddCustomerFilesFunc = (
    id: string,
    fileCreate: FileCreate,
    mutate: any
) => any
export const addCustomerFiles: AddCustomerFilesFunc = (
    id,
    fileCreate,
    mutate
) => {
    mutate(`/customer/${id}/files`, () =>
        CustomerService.addCustomerFileApiV1CustomerIdFilesPost(
            id,
            fileCreate
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type EditCustomerObjectFunc = (
    id: string,
    object_id: string,
    FileUpdate: FileUpdate,
    mutate: any
) => any
export const editCustomerFile: EditCustomerObjectFunc = (
    id,
    object_id,
    fileUpdate,
    mutate
) => {
    mutate(`/customer/${id}/files`, () =>
        CustomerService.editCustomerFilesApiV1CustomerIdFilesFileIdPut(
            id,
            object_id,
            fileUpdate
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type DeleteCustomerObjectFunc = (
    id: string,
    object_id: string,
    mutate: any
) => any
export const deleteCustomerFile: DeleteCustomerObjectFunc = (
    id,
    object_id,
    mutate
) => {
    mutate(`/customer/${id}/files`, () =>
        CustomerService.deleteCustomerFileApiV1CustomerIdFilesFileIdDelete(
            id,
            object_id
        ).catch((reason) => toast.error(reason.toString()))
    )
}
