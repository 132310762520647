export async function placesGeocoding(customerAddress: string) {
    const mapToLocation = (result: google.maps.GeocoderResult) =>
        result?.geometry?.location
    const accuracyOrder = [
        'ROOFTOP',
        'RANGE_INTERPOLATED',
        'GEOMETRIC_CENTER',
        'APPROXIMATE',
    ]
    return fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            customerAddress
        )}&key=${process.env.NEXT_PUBLIC_GOOGLE_GEOCODING_ACCESS_TOKEN}`
    )
        .then((res) => res.json())
        .then((res: google.maps.GeocoderResponse) => {
            const initialResults: google.maps.GeocoderResult[] =
                res.results.sort(
                    (a, b) =>
                        accuracyOrder.indexOf(a.geometry.location_type) -
                        accuracyOrder.indexOf(b.geometry.location_type)
                )

            return {
                bestResult: initialResults
                    .filter(
                        (result: google.maps.GeocoderResult) =>
                            result?.geometry?.location_type === 'ROOFTOP'
                    )
                    .map(mapToLocation)[0],
                initialResults: initialResults.map(mapToLocation),
            }
        })
}
