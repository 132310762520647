/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Incident } from '../models/Incident';
import type { IncidentCreate } from '../models/IncidentCreate';
import type { IncidentSimple } from '../models/IncidentSimple';
import type { IncidentUpdate } from '../models/IncidentUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IncidentService {

    /**
     * Create Incident For Shift Instance
     * @param shiftId
     * @param date
     * @param index
     * @param requestBody
     * @param customerId
     * @param siteVisitOperationId
     * @returns IncidentSimple Successful Response
     * @throws ApiError
     */
    public static createIncidentForShiftInstanceApiV1IncidentsShiftIdDateIndexPost(
        shiftId: string,
        date: string,
        index: number,
        requestBody: IncidentCreate,
        customerId?: (string | null),
        siteVisitOperationId?: (string | null),
    ): CancelablePromise<IncidentSimple> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/incidents/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'customer_id': customerId,
                'site_visit_operation_id': siteVisitOperationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Adhoc Incident
     * @param customerId
     * @param requestBody
     * @param shiftInstanceId
     * @param siteVisitOperationId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createAdhocIncidentApiV1IncidentsCustomerCustomerIdPost(
        customerId: string,
        requestBody: IncidentCreate,
        shiftInstanceId?: (string | null),
        siteVisitOperationId?: (string | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/incidents/customer/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'shift_instance_id': shiftInstanceId,
                'site_visit_operation_id': siteVisitOperationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Incident For Id
     * @param id
     * @param requestBody
     * @returns Incident Successful Response
     * @throws ApiError
     */
    public static updateIncidentForIdApiV1IncidentsIdPut(
        id: string,
        requestBody: IncidentUpdate,
    ): CancelablePromise<Incident> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/incidents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Incident
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static archiveIncidentApiV1IncidentsIdDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/incidents/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
