import React from 'react'

import { Box, Grid, styled, Tab, Tabs, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { DetailsTab } from '../../types/detailsTab'

const TabBox = styled(Box)({
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: '#9CA3AF',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
})

const TabText = styled(Typography)({
    fontWeight: 600,
    paddingTop: '12px',
})
const StyledTab = styled(Tab)({
    textTransform: 'capitalize',
    color: '#9CA3AF',
    opacity: 1,
    whiteSpace: 'nowrap',
    '&:hover': {
        color: 'black',
    },
})

function removeQueryString(url: string) {
    return url.split('?')[0]
}

const hideOnMobileSx = { display: { xs: 'none', sm: 'flex' } }

export const DetailTabs = ({
    data,
    variant = 'scrollable',
    extraTabEnd,
    manualTabIndex,
}: DetailTabProps) => {
    const router = useRouter()
    const activeTabIndex = data.findIndex((tab) =>
        tab ? removeQueryString(router.asPath).startsWith(tab.linkRoot) : false
    )
    // because we sometimes dynamically add tabs, this line prevents warnings from -1 not being found
    const activeTabResult = activeTabIndex === -1 ? 0 : activeTabIndex
    const activeTab =
        manualTabIndex !== undefined ? manualTabIndex : activeTabResult
    return (
        <TabBox>
            <Tabs value={activeTab} variant={variant}>
                {data.map((tab, index) => {
                    if (!tab) return
                    const sx = index === activeTab ? { color: '#4338CA' } : {}
                    return (
                        <Box
                            sx={tab.hideOnMobile ? hideOnMobileSx : null}
                            key={tab.name}
                        >
                            <Link
                                href={tab.link}
                                passHref
                                key={tab.name}
                                legacyBehavior
                            >
                                <StyledTab
                                    wrapped
                                    label={
                                        <Box display="flex" flexDirection="row">
                                            <TabText sx={sx}>
                                                {tab.name}
                                            </TabText>
                                            {tab.extra ? (
                                                <Box
                                                    paddingLeft={0.5}
                                                    display="flex"
                                                    alignItems="end"
                                                >
                                                    {tab.extra}
                                                </Box>
                                            ) : null}
                                        </Box>
                                    }
                                    value={index}
                                />
                            </Link>
                        </Box>
                    )
                })}
            </Tabs>
            {extraTabEnd && <Grid mb={1}>{extraTabEnd}</Grid>}
        </TabBox>
    )
}

type DetailTabProps = {
    data: (DetailsTab | null)[]
    variant?: 'fullWidth' | 'standard' | 'scrollable' | undefined
    extraTabEnd?: React.ReactNode
    manualTabIndex?: number
}
