import { toast } from 'react-toastify'
import useSWR from 'swr'
import { CHQEarningCode, PayrollService } from '../generated'

import { errorReasonToString } from '../utils/errorUtils'
import { format } from 'date-fns'
import { FunctionStatusType } from './sharedTypes'
import { BelfryAPIEmployeeTaxParameterResponse } from 'src/generated/models/BelfryAPIEmployeeTaxParameterResponse'
import { BelfryEmployeeOrContractorDefaultNetPaySplit } from 'src/generated/models/BelfryEmployeeOrContractorDefaultNetPaySplit'

type LoadEmployeeTaxParametersType = {
    parameters: BelfryAPIEmployeeTaxParameterResponse | undefined
} & FunctionStatusType

type LoadWorkerNetPaySplitType = {
    split: BelfryEmployeeOrContractorDefaultNetPaySplit | undefined
} & FunctionStatusType

export const useLoadFirmPayrollSetupStatus = () => {
    const { data, error } = useSWR(`/firm_payroll_setup_status/`, () =>
        PayrollService.companyStatusApiV1PayrollCompanyStatusGet()
    )
    return data
}

export const useLoadFirmPayrollList = (cursor?: string) => {
    const { data, error } = useSWR([`firm_payroll_list`, cursor], () =>
        PayrollService.getFirmPayrollsPaginatedApiV1PayrollPaginatedListGet(
            cursor
        )
    )
    return data
}

export const useLoadPayrollDetailLink = (id: string | false) => {
    const key = id ? [`/payroll_detail_link/`, id] : null
    const { data, error } = useSWR(
        key,
        () => {
            return PayrollService.getPayrollEditLinkApiV1PayrollEditLinkPost(
                id as any
            )
        },
        { refreshInterval: 0, revalidateOnFocus: false }
    )
    if (error) {
        toast.error(errorReasonToString(error))
    }
    return data
}

type LoadEmployeeTaxParametersFunc = (
    id: string
) => LoadEmployeeTaxParametersType
export const useLoadEmployeeTaxParameters: LoadEmployeeTaxParametersFunc = (
    id
) => {
    const { data, error } = useSWR(`/tax_parameters/${id}`, () =>
        PayrollService.payrollEmployeeTaxParametersApiV1PayrollAdminEmployeeTaxParametersGet(
            id
        )
    )
    return {
        parameters: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadWorkerNetPaySplitFunc = (id: string) => LoadWorkerNetPaySplitType
export const useLoadWorkerNetPaySplit: LoadWorkerNetPaySplitFunc = (id) => {
    const { data, error } = useSWR(`/net_pay_split/${id}`, () =>
        PayrollService.getEmployeeOrContractorNetPaySplitsApiV1PayrollWorkerOfficerIdNetPaySplitsGet(
            id
        )
    )
    return {
        split: data,
        isLoading: !error && !data,
        isError: error,
    }
}

export const useLoadOfficerPayrollSetupStatus = (id: string) => {
    const { data, error } = useSWR([`/officer_payroll_setup_status/`, id], () =>
        PayrollService.officerStatusApiV1PayrollOfficerIdStatusGet(id)
    )
    return data
}

export const useLoadPaySchedule = (
    startDate: Date,
    endDate: Date,
    payGroupId: string | undefined
) => {
    const { data, error } = useSWR(
        [`/payschedule/`, startDate, endDate, payGroupId],
        () =>
            PayrollService.getPayscheduleApiV1PayrollPayscheduleGet(
                format(startDate, 'yyyy-MM-dd'),
                format(endDate, 'yyyy-MM-dd'),
                payGroupId
            ),
        { refreshInterval: 0, revalidateOnFocus: false }
    )
    return data
}

export const useLoadCustomEarningCodes = () => {
    const { data, error } = useSWR(`/earning_codes/`, () =>
        PayrollService.getActiveEarningCodeListApiV1PayrollEarningCodeGet()
    )
    return data
}

type createCustomEarningCodeFunc = (
    earningCode: CHQEarningCode,
    mutate: any
) => Promise<any>
export const createCustomEarningCode: createCustomEarningCodeFunc = (
    earningCode,
    mutate
) => {
    return mutate(`/earning_codes/`, async () => {
        return await PayrollService.createEarningCodeApiV1PayrollEarningCodePost(
            earningCode
        ).catch((reason) => toast.error(errorReasonToString(reason)))
    })
}

type downloadPaperChecksPDFFunc = (payrollId: string) => void

export const downloadPaperChecksPDF: downloadPaperChecksPDFFunc = async (
    payrollId
) => {
    await PayrollService.downloadPayrollChecksApiV1PayrollPayrollIdChecksGet(
        payrollId
    )
        .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `paper-checks-${payrollId}.pdf`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download PDF')
            }
        })
}

export const downloadCashReqReport: downloadPaperChecksPDFFunc = async (
    payrollId
) => {
    await PayrollService.companyPayrollCashReqReportCsvApiV1PayrollCompanyCashReqReportCsvGet(
        payrollId
    )
        .then((res) => {
            const blob = new Blob([res], { type: 'application/csv' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `cash-req-${payrollId}.csv`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download CSV')
            }
        })
}

type downloadW2PreviewFunc = () => void
export const downloadW2Preview: downloadW2PreviewFunc = async () => {
    await PayrollService.payrollCompanyW2PreviewApiV1PayrollCompanyW2PreviewGet()
        .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `w2-preview.csv`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download CSV')
            }
        })
}
