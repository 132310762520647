import React, { ReactNode, useRef, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { styled } from '@mui/material'
import {
    Widget,
    FileUpload,
    FileInfo,
    WidgetAPI,
} from '@uploadcare/react-widget'
import { getSignedUrlSpecialAuth } from '../../services/signing'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'

import { toast } from 'react-toastify'
/*
 * UploadWrapper - wraps children components to make a clickable region which is used for uploading files.
 * If the desired behavior is to simply have a button usable for uploading files, simply use the Widget component
 * directly from uploadcare.
 */

interface UploadWrapperProps {
    onFileSelect: (fileInfo: FileUpload | null) => void
    children?: ReactNode
    hideOverlay?: boolean
    validators?: ((fileInfo: FileInfo) => void)[]
}

const CenterDiv = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
})

export const fileTypeLimit = (allowedFileTypes: string) => {
    const types = allowedFileTypes.split(' ')

    return function (fileInfo: FileInfo) {
        if (fileInfo.name === null) {
            return
        }
        const extension = fileInfo.name.split('.').pop()

        if (extension && !types.includes(extension)) {
            toast.error('Upload must be a pdf.')
            throw new Error('fileType')
        }
    }
}

export const UploadWrapper = ({
    onFileSelect,
    children,
    hideOverlay,
    validators = undefined,
}: UploadWrapperProps) => {
    const widgetApi = useRef<WidgetAPI>()
    const { getAccessTokenSilently } = useAuth0()
    const [accessToken, setAccessToken] = useState<string | null>(null)

    useEffect(() => {
        const getUserToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getUserToken()
    }, [getAccessTokenSilently])

    return (
        <div
            onClick={() => widgetApi?.current?.openDialog()}
            className="container"
        >
            {!hideOverlay ? (
                <div className="overlay">
                    <CenterDiv>
                        <PhotoCameraIcon
                            style={{ zIndex: 40, position: 'absolute' }}
                        />
                    </CenterDiv>
                </div>
            ) : null}
            {children}
            <div style={{ display: 'none' }}>
                <Widget
                    // @ts-ignore
                    ref={widgetApi}
                    style={{ zIndex: 10, display: 'none' }}
                    publicKey={
                        process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY ?? null
                    }
                    tabs={'file'}
                    // @ts-ignore
                    onFileSelect={onFileSelect}
                    previewStep
                    // @ts-ignore
                    crop
                    validators={validators}
                    cdnBase={process.env.NEXT_PUBLIC_UPLOADCARE_CDN_BASE}
                    previewUrlCallback={(previewUrl, fileInfo) => {
                        if (accessToken && fileInfo?.cdnUrl) {
                            return getSignedUrlSpecialAuth(
                                fileInfo?.cdnUrl,
                                accessToken
                            )
                        } else {
                            return ''
                        }
                    }}
                />
            </div>
            <style>{`
                .container {
                    cursor: pointer;
                    position: relative;
                }
                .overlay {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                }
                .container:hover .overlay {
                    opacity: 1;
                }
            `}</style>
        </div>
    )
}
