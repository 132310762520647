import React from 'react'
import { Box, Grid } from '@mui/material'
import { DetailTabs } from '../DetailTabs'
import { DetailsTab } from '../../types/detailsTab'
type LayoutProps = React.PropsWithChildren<{}>

export const TabbedLicensesLayout = ({ children }: LayoutProps) => {
    let tabLinks: DetailsTab[] = [
        {
            name: 'Licenses and Certifications',
            link: `/analytics/licenses_certs`,
            linkRoot: `/analytics/licenses_certs`,
        },
    ]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container>
                <Grid item xs={12}>
                    <DetailTabs data={tabLinks} variant={'fullWidth'} />
                </Grid>
            </Grid>
            <main>{children}</main>
        </Box>
    )
}
