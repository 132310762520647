import {
    styled,
    Button,
    Typography,
    FormControl,
    MenuItem,
    Skeleton,
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { addDays, format } from 'date-fns'
import { Firm, TerminateOfficerOffCycleFinalPayroll } from '../../../generated'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { ContentModal } from '../../Modal'
import { ControlledSelect } from '../../ControlledSelect'
import { FormControlledField } from '../../Form'
import { useLoadOfficerPayrollSetupStatus } from '../../../services/payroll'
import { terminatedOfficerOffCycleFinalPayrollV2 } from '../../../services/officer'
import { checkhq_processing_period_days_to_int } from '../../Timekeeping/helpers'
import {
    OffCyclePayrollOptionsAndPayDayInput,
    convertOffCycleOptionStringsToBools,
} from '../../Payroll/OffCyclePayrollOptions'
import { detailedLinkErrorToToast } from '../../Payroll/detailedLinkErrorToToast'

const FullWidthFormControl = styled(FormControl)({
    width: '100%',
})

type TerminateOffCycleModalProps = {
    open: boolean
    handleClose: () => void
    officerName: string | undefined
    officerId: string
    firm: Firm
}

export const TerminationCreateOffCyclePayModal = ({
    open,
    handleClose,
    officerName,
    officerId,
    firm,
}: TerminateOffCycleModalProps) => {
    const today = new Date()
    const router = useRouter()
    const payrollSetupStatus = useLoadOfficerPayrollSetupStatus(officerId)
    const { handleSubmit, control, watch } =
        useForm<TerminateOfficerOffCycleFinalPayroll>()

    const [isInProgress, setIsInProgress] = React.useState(false)
    const onSubmit = async (data: TerminateOfficerOffCycleFinalPayroll) => {
        setIsInProgress(true)
        let payrollId
        try {
            payrollId = await terminatedOfficerOffCycleFinalPayrollV2(
                officerId,
                data
            )
        } catch (error: any) {
            detailedLinkErrorToToast(error)
            setIsInProgress(false)
            return
        }
        toast.dismiss()
        toast.success(
            'Timecards sent to draft off-cycle payroll run, taking you there'
        )
        router.push(`/payroll?payrollId=${payrollId}`)
    }
    if (!payrollSetupStatus || !payrollSetupStatus.checkhq_worker) {
        return <Skeleton />
    }
    const { checkhq_worker } = payrollSetupStatus
    const hasBankAccount = checkhq_worker.bank_accounts.length > 0

    const minLatePaydayDate = addDays(
        today,
        checkhq_processing_period_days_to_int(firm.checkhq_processing_period)
    )
    const minDate =
        watch('payment_method') === 'manual' ? today : minLatePaydayDate

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            <Typography variant="h4" mb={2}>
                Termination: Off-Cycle Payroll
            </Typography>
            <Typography mb={2}>
                This payrun will include all work that has not yet been paid for
                the current and previous pay period.
            </Typography>
            <form
                onSubmit={handleSubmit((data) => {
                    if (data.payday_date < format(minDate, 'yyyy-MM-dd')) {
                        toast.warn('Payday too early for payment method')
                        return
                    }
                    data.off_cycle_options =
                        convertOffCycleOptionStringsToBools(
                            data.off_cycle_options
                        )
                    onSubmit(data)
                })}
            >
                <FormControlledField
                    requiredField
                    label="Payment Method"
                    error={undefined}
                >
                    <ControlledSelect
                        name={'payment_method'}
                        control={control}
                        defaultValue={
                            hasBankAccount ? 'direct_deposit' : 'manual'
                        }
                    >
                        <MenuItem key="manual" value="manual">
                            Manual/Paper Check
                        </MenuItem>
                        {hasBankAccount ? (
                            <MenuItem
                                key="direct_deposit"
                                value="direct_deposit"
                            >
                                Direct Deposit
                            </MenuItem>
                        ) : null}
                    </ControlledSelect>
                </FormControlledField>
                <OffCyclePayrollOptionsAndPayDayInput
                    control={control}
                    minDate={minDate}
                />
                <Button
                    variant="contained"
                    disabled={isInProgress}
                    sx={{ mt: 1 }}
                    fullWidth
                    size="large"
                    type="submit"
                >
                    Create off-cycle payroll for {officerName}
                </Button>
            </form>
        </ContentModal>
    )
}
