/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IncidentType {
    ALARM_FALSE_ALARM = 'ALARM_FALSE_ALARM',
    ALARM_MISCELLANEOUS = 'ALARM_MISCELLANEOUS',
    ARREST = 'ARREST',
    ASSAULT = 'ASSAULT',
    DENIED_ACCESS = 'DENIED_ACCESS',
    DISTURBANCE_ALCOHOL_DRUGS = 'DISTURBANCE_ALCOHOL_DRUGS',
    DISTURBANCE_FIGHTING = 'DISTURBANCE_FIGHTING',
    DISTURBANCE_FORCED_ENTRY = 'DISTURBANCE_FORCED_ENTRY',
    DISTURBANCE_INDECENCY = 'DISTURBANCE_INDECENCY',
    DISTURBANCE_LOITERING = 'DISTURBANCE_LOITERING',
    DISTURBANCE_NOISE = 'DISTURBANCE_NOISE',
    DISTURBANCE_SOLICITING = 'DISTURBANCE_SOLICITING',
    DISTURBANCE_SUSPICIOUS_PERSON = 'DISTURBANCE_SUSPICIOUS_PERSON',
    DISTURBANCE_VAGRANT_ACTIVITY = 'DISTURBANCE_VAGRANT_ACTIVITY',
    DISTURBANCE_VANDALISM = 'DISTURBANCE_VANDALISM',
    EMERGENCY_FIRE_SMOKE = 'EMERGENCY_FIRE_SMOKE',
    EMERGENCY_GAS = 'EMERGENCY_GAS',
    EMERGENCY_MEDICAL = 'EMERGENCY_MEDICAL',
    EMERGENCY_POWER_FAILURE = 'EMERGENCY_POWER_FAILURE',
    EMERGENCY_WATER_DAMAGE = 'EMERGENCY_WATER_DAMAGE',
    EMERGENCY_WEATHER = 'EMERGENCY_WEATHER',
    EQUIPMENT_DAMAGED = 'EQUIPMENT_DAMAGED',
    EQUIPMENT_MALFUNCTION = 'EQUIPMENT_MALFUNCTION',
    EQUIPMENT_MISSING = 'EQUIPMENT_MISSING',
    INJURY = 'INJURY',
    MAINTENANCE_DOORS = 'MAINTENANCE_DOORS',
    MAINTENANCE_ELEVATOR = 'MAINTENANCE_ELEVATOR',
    MAINTENANCE_GATES = 'MAINTENANCE_GATES',
    MAINTENANCE_LEAKS = 'MAINTENANCE_LEAKS',
    MAINTENANCE_LIGHTS = 'MAINTENANCE_LIGHTS',
    MISSING_CHILD = 'MISSING_CHILD',
    MISSING_PERSON = 'MISSING_PERSON',
    MOTOR_VEHICLE_ABANDONED = 'MOTOR_VEHICLE_ABANDONED',
    MOTOR_VEHICLE_ACCIDENT = 'MOTOR_VEHICLE_ACCIDENT',
    MOTOR_VEHICLE_ASSIST = 'MOTOR_VEHICLE_ASSIST',
    MOTOR_VEHICLE_BREAK_IN = 'MOTOR_VEHICLE_BREAK_IN',
    MOTOR_VEHICLE_DISABLED = 'MOTOR_VEHICLE_DISABLED',
    MOTOR_VEHICLE_SUSPICIOUS = 'MOTOR_VEHICLE_SUSPICIOUS',
    MOTOR_VEHICLE_TOWED = 'MOTOR_VEHICLE_TOWED',
    MOTOR_VEHICLE_UNAUTHORIZED = 'MOTOR_VEHICLE_UNAUTHORIZED',
    OPEN_GARAGE_DOOR = 'OPEN_GARAGE_DOOR',
    OTHER = 'OTHER',
    POLICE_REPORTS = 'POLICE_REPORTS',
    PROPERTY_DAMAGED = 'PROPERTY_DAMAGED',
    PROPERTY_FOUND = 'PROPERTY_FOUND',
    PROPERTY_MISSING = 'PROPERTY_MISSING',
    PROPERTY_RETURNED = 'PROPERTY_RETURNED',
    ROBBERY = 'ROBBERY',
    THEFT = 'THEFT',
    TRESPASS = 'TRESPASS',
    UNSECURE_DOOR = 'UNSECURE_DOOR',
    UNSECURE_GATE = 'UNSECURE_GATE',
}
