import { Box, Grid } from '@mui/material'
import React from 'react'
import { useRouter } from 'next/router'
import { Breadcrumb } from '../Breadcrumb'

type StepperLayoutProps = {
    children: JSX.Element
}

export function StepperLayout({ children }: StepperLayoutProps) {
    const router = useRouter()
    const currentPath = router.asPath
    const backPath =
        currentPath.split('/').slice(0, -1).join('/') + '/configuration'

    return (
        <Grid container direction="column">
            <Grid item>
                <Box sx={{ padding: 1 }}>
                    <Breadcrumb
                        path={backPath}
                        label={'Back to Configurations'}
                    />
                </Box>
            </Grid>
            <Grid item>
                <main>{children}</main>
            </Grid>
        </Grid>
    )
}
