import { Modal, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useIsMobileScreenSize } from '../../hooks/useIsMobileScreenSize'
import { Loading } from '../Loading'

const desktopStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '55vw',
    maxHeight: '90vh',
    maxWidth: '1200px',
    minWidth: '600px',
    p: 4,
    // bgcolor: 'background.paper',
    border: ' 1px solid rgba(156, 163, 175, 0.32)',
    boxShadow: '0px 2px 40px 12px rgba(17, 10, 101, 0.08)',
    backgroundColor: '#fff',
    borderRadius: '12px',
    gap: 8,
    overflow: 'auto',
}

const mobileStyle = {
    backgroundColor: 'background.paper',
    height: '100vh',
    overflowY: 'scroll',
}

const closeButtonStyle = {
    position: 'absolute' as 'absolute',
    top: '0%',
    right: '0%',
    backgroundColor: 'background.paper',
    zIndex: 100001,
}

type ContentModalProps = {
    isOpen: boolean
    handleClose: () => void
    children?: React.ReactNode
    showCloseButton?: boolean | false
    extremeZIndex?: boolean | false
    disableEnforceFocus?: boolean | false
    disableOutline?: boolean
    contentLoading?: boolean
}

export const ContentModal = ({
    isOpen,
    handleClose,
    children,
    showCloseButton,
    extremeZIndex,
    disableEnforceFocus,
    disableOutline = false,
    contentLoading = false,
}: ContentModalProps) => {
    const isMobileScreenSize = useIsMobileScreenSize()
    const style = isMobileScreenSize ? mobileStyle : desktopStyle
    const maybeExtremeZIndex = extremeZIndex ? { sx: { zIndex: 100000 } } : {}
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            {...maybeExtremeZIndex}
            disableEnforceFocus={disableEnforceFocus}
        >
            <Box sx={!disableOutline ? style : { ...style, outline: 'none' }}>
                {contentLoading ? (
                    <Loading />
                ) : (
                    <>
                        {showCloseButton ? (
                            <Box sx={closeButtonStyle}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : null}
                        {children}
                    </>
                )}
            </Box>
        </Modal>
    )
}
