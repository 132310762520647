/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PositionCreate } from '../models/PositionCreate';
import type { PositionCreateResponse } from '../models/PositionCreateResponse';
import type { PositionListing } from '../models/PositionListing';
import type { PositionUpdate } from '../models/PositionUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PositionsService {

    /**
     * List Positions
     * @param customerId
     * @returns PositionListing Successful Response
     * @throws ApiError
     */
    public static listPositionsApiV1PositionsGet(
        customerId: string,
    ): CancelablePromise<Array<PositionListing>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/positions',
            query: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Position
     * @param requestBody
     * @returns PositionCreateResponse Successful Response
     * @throws ApiError
     */
    public static createPositionApiV1PositionsPost(
        requestBody: PositionCreate,
    ): CancelablePromise<PositionCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/positions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Position
     * @param positionId
     * @param requestBody
     * @returns PositionCreateResponse Successful Response
     * @throws ApiError
     */
    public static updatePositionApiV1PositionsPositionIdPut(
        positionId: string,
        requestBody: PositionUpdate,
    ): CancelablePromise<PositionCreateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/positions/{position_id}',
            path: {
                'position_id': positionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
