import React, { createContext, useMemo, useState } from 'react'
import { OpenAPI, Region } from '../../generated'
import { useCurrentUserRegions } from '../../services/region'

export const RegionContext = createContext(null)

type RegionContextWrapperProps = React.PropsWithChildren<{}>

export const RegionContextWrapper = ({
    children,
    ...props
}: RegionContextWrapperProps) => {
    const { regions } = useCurrentUserRegions()
    const [selectedRegionIds, setSelectedRegionIds] = useState<string[]>([])

    const selectedRegions = useMemo<Region[]>(() => {
        return regions.filter((value) => selectedRegionIds.includes(value.id))
    }, [selectedRegionIds])

    const getRegionHeader = async () => {
        const allOrNoRegions =
            selectedRegions.length == 0 ||
            selectedRegions.length == regions.length
        return allOrNoRegions
            ? {}
            : { 'active-regions': selectedRegionIds.join(';') }
    }

    // @ts-ignore - complains on empty map
    OpenAPI.HEADERS = getRegionHeader

    return (
        <RegionContext.Provider
            // @ts-ignore - initially null but always set
            value={{
                selectedRegions,
                selectedRegionIds,
                setSelectedRegionIds,
            }}
        >
            {children}
        </RegionContext.Provider>
    )
}
