import React from 'react'
import { Customer } from '../../generated'
import { styled, Button, Grid, Typography, ButtonGroup } from '@mui/material'
import { unarchiveCustomer } from '../../services/customer'
import { Loading } from '../Loading'
import { useSWRConfig } from 'swr'
import { ApiError } from '../../generated'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { ContentModal } from '../Modal'

type UnarchiveModalProps = {
    open: boolean
    handleClose: () => void
    customerName: string | undefined
    customerId: string
}

export const UnarchiveModal = ({
    open,
    handleClose,
    customerName,
    customerId,
}: UnarchiveModalProps) => {
    const { mutate } = useSWRConfig()
    const router = useRouter()
    const [submitting, isSubmitted] = React.useState<boolean>(false)
    const unarchiveCustomerCallback = React.useCallback(() => {
        isSubmitted(true)
        return unarchiveCustomer(customerId, mutate)
            .then((value: any) => {
                isSubmitted(false)
                handleClose()
            })
            .catch((error: ApiError) => {
                toast.error(error?.message)
                isSubmitted(false)
            })
    }, [])

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            {submitting && <Loading style={{ minHeight: undefined }} />}
            {!submitting && (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h2">Unarchive Customer</Typography>
                        <Typography variant="body1">
                            This will restore {customerName} to active status.
                        </Typography>
                    </Grid>
                    <Grid item marginTop={3}>
                        <ButtonGroup fullWidth>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => unarchiveCustomerCallback()}
                            >
                                Unarchive Customer
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )}
        </ContentModal>
    )
}

const CustomerUnarchiveButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
})

type CustomerUnarchiveButtonProps = {
    customer?: Customer
    id: string
}
export function CustomerUnarchiveButton({
    customer,
    id,
}: CustomerUnarchiveButtonProps) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <CustomerUnarchiveButtonContainer>
            <UnarchiveModal
                open={open}
                handleClose={handleClose}
                customerName={customer?.name}
                customerId={id}
            />
            <Button variant="text" color="primary" onClick={handleOpen}>
                Unarchive Customer
            </Button>
        </CustomerUnarchiveButtonContainer>
    )
}
