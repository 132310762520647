import React from 'react'

import { Box, styled, Tab, Tabs } from '@mui/material'
import { useRouter } from 'next/router'
import { DetailsTab } from '../../types/detailsTab'

const StyledTab = styled(Tab)({
    '&.Mui-selected': {
        backgroundColor: '#111827',
        color: '#fff',
        borderRadius: 8,
        height: '36px',
        minHeight: '36px',
    },
    color: '#66708D',
    height: '36px',
    minHeight: '36px',
})

function removeQueryString(url: string) {
    return url.split('?')[0]
}

type InnerTabProps = {
    data: DetailsTab[]
}

export const InnerTabs = ({ data }: InnerTabProps) => {
    const router = useRouter()
    const activeTab = data.findIndex(
        (tab) => removeQueryString(router.asPath) === tab.link
    )
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        router.push(data[newValue].link)
    }

    return (
        <Box mb={5}>
            <Tabs
                value={activeTab}
                onChange={handleChange}
                TabIndicatorProps={{
                    sx: {
                        display: 'none',
                    },
                }}
            >
                {data.map((tab, index) => {
                    if (!tab) return
                    return (
                        <StyledTab
                            onClick={(event) => {
                                event.preventDefault()
                            }}
                            key={tab.name}
                            label={tab.name}
                            value={index}
                        />
                    )
                })}
            </Tabs>
        </Box>
    )
}
