import React from 'react'

import { Chip, Grid } from '@mui/material'

export const OfficerInactiveBadge = () => {
    return (
        <Grid item justifyContent="flex-end" display="flex" alignItems="center">
            <Chip label={'Inactive'} />
        </Grid>
    )
}
