/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SigningService {

    /**
     * Sign Url
     * Signs the passed in resource url and redirects to the uploadcare cdn.
     * @param url
     * @returns any Successful Response
     * @throws ApiError
     */
    public static signUrlApiV1SignGet(
        url: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sign',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign Url Custom
     * Signs the passed in resource url and redirects to the uploadcare cdn.
     *
     * Takes in the JWT via a query param to support uploadcares format
     * @param url
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static signUrlCustomApiV1SignCustomGet(
        url: string,
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sign/custom',
            query: {
                'url': url,
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign Zendesk Jwt
     * @returns string Successful Response
     * @throws ApiError
     */
    public static signZendeskJwtApiV1SignZendeskGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sign/zendesk',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
