/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CustomerMetadata = {
    id: string;
    name: string;
    region_id?: string;
    us_state: string;
    address?: string;
    checkhq_id?: string;
    type?: CustomerMetadata.type;
};

export namespace CustomerMetadata {

    export enum type {
        SITE = 'SITE',
        PATROL = 'PATROL',
    }


}

