import React from 'react'
import { styled, Button, Skeleton } from '@mui/material'
import { Officer } from '../../generated'
import { useRouter } from 'next/router'
import { OfficerRestoreButton } from './OfficerRestoreButton'

const OfficerTerminateButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
})

type OfficerTerminateButtonProps = {
    officer: Officer | undefined
    id: string
    isTerminationAndPayrollPrivileged: boolean
}
export function OfficerTerminateAndRestoreButton({
    officer,
    id,
    isTerminationAndPayrollPrivileged,
}: OfficerTerminateButtonProps) {
    const router = useRouter()
    if (!officer) {
        return <Skeleton />
    }

    const termination_button = (
        <>
            <Button
                color="primary"
                disabled={!isTerminationAndPayrollPrivileged}
                fullWidth
                onClick={() => {
                    router.push({ query: { id, termination_step: 1 } })
                }}
            >
                Terminate Worker
            </Button>
        </>
    )

    const button = officer.is_terminated ? (
        <OfficerRestoreButton
            officer={officer}
            id={id}
            isTerminationAndPayrollPrivileged={
                isTerminationAndPayrollPrivileged
            }
        />
    ) : (
        termination_button
    )

    return (
        <OfficerTerminateButtonContainer>
            {button}
        </OfficerTerminateButtonContainer>
    )
}
