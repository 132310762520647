/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConstraintResultNamesEnum {
    AVAILABLE = 'available',
    HAS_PREF_NON_UNAVAIL = 'has_pref_non_unavail',
    HAS_PREF_AVAIL = 'has_pref_avail',
    HAS_CERTS = 'has_certs',
    HAS_LICS = 'has_lics',
    HAS_CREDENTIALS = 'has_credentials',
    HAS_ALLOWED_PAY_RATE = 'has_allowed_pay_rate',
    HAS_CUST_EXP = 'has_cust_exp',
    HAS_POST_EXP = 'has_post_exp',
    IS_ON_TIME_OFF = 'is_on_time_off',
}
