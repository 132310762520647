import { useMediaQuery, useTheme } from '@mui/material'

// Generally preferable to use 1) MUI's built in responsive functionality (e.g. sx={ display: { xs: 'none', sm: 'flex' })
// or 2) WithShowDesktopOrMobileHOC instead of using this (useIsMobileScreenSize) directly inside a
// component and creating a bunch of conditional presentation logic
export const useIsMobileScreenSize = () => {
    const theme = useTheme()
    const isMobileScreenSize = useMediaQuery(theme.breakpoints.down('sm'))
    return isMobileScreenSize
}
