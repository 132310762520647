/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BelfryAPIEmployeeTaxParameterResponse } from '../models/BelfryAPIEmployeeTaxParameterResponse';
import type { BelfryEmployeeOrContractorDefaultNetPaySplit } from '../models/BelfryEmployeeOrContractorDefaultNetPaySplit';
import type { CheckHqCompanyStatusResponse } from '../models/CheckHqCompanyStatusResponse';
import type { CheckHqWorkerResponse } from '../models/CheckHqWorkerResponse';
import type { CHQEarningCode } from '../models/CHQEarningCode';
import type { CHQMinimalPayroll } from '../models/CHQMinimalPayroll';
import type { CHQMinimalPayrollResults } from '../models/CHQMinimalPayrollResults';
import type { CHQOffCycleOptions } from '../models/CHQOffCycleOptions';
import type { NonCustomerWorkplace } from '../models/NonCustomerWorkplace';
import type { NonCustomerWorkplaceCreate } from '../models/NonCustomerWorkplaceCreate';
import type { ResultResponse } from '../models/ResultResponse';
import type { ResultResponsePeriod } from '../models/ResultResponsePeriod';
import type { ResultResponseString } from '../models/ResultResponseString';
import type { UrlResponse } from '../models/UrlResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PayrollService {

    /**
     * Payroll Initial Company Setup
     * @param title
     * @param email
     * @param firstName
     * @param middleName
     * @param lastName
     * @param startDate
     * @param firstPeriodEndDate
     * @returns string Successful Response
     * @throws ApiError
     */
    public static payrollInitialCompanySetupApiV1PayrollInitialCompanySetupPost(
        title: string,
        email: string,
        firstName: string,
        middleName: string,
        lastName: string,
        startDate: string,
        firstPeriodEndDate: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/initial_company_setup',
            query: {
                'title': title,
                'email': email,
                'first_name': firstName,
                'middle_name': middleName,
                'last_name': lastName,
                'start_date': startDate,
                'first_period_end_date': firstPeriodEndDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Onboard Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static companyOnboardLinkApiV1PayrollCompanyOnboardLinkGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/onboard_link',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Status
     * @returns CheckHqCompanyStatusResponse Successful Response
     * @throws ApiError
     */
    public static companyStatusApiV1PayrollCompanyStatusGet(): CancelablePromise<CheckHqCompanyStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/status',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Firm Payrolls
     * @returns CHQMinimalPayroll Successful Response
     * @throws ApiError
     */
    public static getFirmPayrollsApiV1PayrollListGet(): CancelablePromise<Array<CHQMinimalPayroll>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/list',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Firm Payrolls Paginated
     * @param cursor
     * @returns CHQMinimalPayrollResults Successful Response
     * @throws ApiError
     */
    public static getFirmPayrollsPaginatedApiV1PayrollPaginatedListGet(
        cursor?: (string | null),
    ): CancelablePromise<CHQMinimalPayrollResults> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/paginated_list',
            query: {
                'cursor': cursor,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payroll Edit Link
     * @param payrollId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getPayrollEditLinkApiV1PayrollEditLinkPost(
        payrollId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/edit_link',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Payroll Draft
     * @param payrollId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deletePayrollDraftApiV1PayrollDeleteDraftDelete(
        payrollId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/payroll/delete_draft',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Non Customer Workplaces
     * @returns NonCustomerWorkplace Successful Response
     * @throws ApiError
     */
    public static getNonCustomerWorkplacesApiV1PayrollNonCustomerWorkplacesGet(): CancelablePromise<Array<NonCustomerWorkplace>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/non_customer_workplaces',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Non Customer Workplace
     * @param requestBody
     * @returns NonCustomerWorkplace Successful Response
     * @throws ApiError
     */
    public static createNonCustomerWorkplaceApiV1PayrollNonCustomerWorkplacesPost(
        requestBody: NonCustomerWorkplaceCreate,
    ): CancelablePromise<Array<NonCustomerWorkplace>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/non_customer_workplaces',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Tax Docs Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollCompanyTaxDocsLinkApiV1PayrollCompanyTaxDocsGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/tax_docs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Authorization Docs Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollCompanyAuthorizationDocsLinkApiV1PayrollCompanyAuthorizationDocsGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/authorization_docs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employee Or Contractor Net Pay Splits
     * @param officerId
     * @returns BelfryEmployeeOrContractorDefaultNetPaySplit Successful Response
     * @throws ApiError
     */
    public static getEmployeeOrContractorNetPaySplitsApiV1PayrollWorkerOfficerIdNetPaySplitsGet(
        officerId: string,
    ): CancelablePromise<BelfryEmployeeOrContractorDefaultNetPaySplit> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/worker/{officer_id}/net_pay_splits',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Payroll Summary
     * @param start
     * @param end
     * @returns any Successful Response
     * @throws ApiError
     */
    public static payrollCompanyPayrollSummaryApiV1PayrollCompanyPayrollSummaryGet(
        start?: (string | null),
        end?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/payroll_summary',
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Payroll Journal Csv
     * @param payrollId
     * @param groupByWorkplace
     * @returns any Successful Response
     * @throws ApiError
     */
    public static companyPayrollJournalCsvApiV1PayrollCompanyPayrollJournalCsvGet(
        payrollId: string,
        groupByWorkplace: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/payroll_journal_csv',
            query: {
                'payroll_id': payrollId,
                'group_by_workplace': groupByWorkplace,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Payroll Preview Csv
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static companyPayrollPreviewCsvApiV1PayrollCompanyPayrollPreviewCsvGet(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/payroll_preview_csv',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Payroll Paper Check Report Csv
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static companyPayrollPaperCheckReportCsvApiV1PayrollCompanyPayrollPaperCheckReportCsvGet(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/payroll_paper_check_report_csv',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Blank Off Cycle Payroll
     * @param startDate
     * @param endDate
     * @param payday
     * @param requestBody
     * @returns ResultResponseString Successful Response
     * @throws ApiError
     */
    public static createBlankOffCyclePayrollApiV1PayrollOffCyclePayrollPost(
        startDate: string,
        endDate: string,
        payday: string,
        requestBody?: (CHQOffCycleOptions | null),
    ): CancelablePromise<ResultResponseString> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/off_cycle_payroll',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'payday': payday,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payschedule
     * @param startDate
     * @param endDate
     * @param payGroupId
     * @returns ResultResponsePeriod Successful Response
     * @throws ApiError
     */
    public static getPayscheduleApiV1PayrollPayscheduleGet(
        startDate: string,
        endDate: string,
        payGroupId?: (string | null),
    ): CancelablePromise<ResultResponsePeriod> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/payschedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Active Earning Code List
     * @returns CHQEarningCode Successful Response
     * @throws ApiError
     */
    public static getActiveEarningCodeListApiV1PayrollEarningCodeGet(): CancelablePromise<Array<CHQEarningCode>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/earning_code',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Earning Code
     * @param requestBody
     * @returns CHQEarningCode Successful Response
     * @throws ApiError
     */
    public static createEarningCodeApiV1PayrollEarningCodePost(
        requestBody: CHQEarningCode,
    ): CancelablePromise<Array<CHQEarningCode>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/earning_code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Officer Status
     * @param id
     * @returns CheckHqWorkerResponse Successful Response
     * @throws ApiError
     */
    public static officerStatusApiV1PayrollOfficerIdStatusGet(
        id: string,
    ): CancelablePromise<CheckHqWorkerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/officer/{id}/status',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Officer Onboard Link
     * @param id
     * @param noSendSms
     * @returns string Successful Response
     * @throws ApiError
     */
    public static officerOnboardLinkApiV1PayrollOfficerIdOnboardLinkPost(
        id: string,
        noSendSms?: (boolean | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officer/{id}/onboard_link',
            path: {
                'id': id,
            },
            query: {
                'noSendSMS': noSendSms,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Officer Tax Docs Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminOfficerTaxDocsLinkApiV1PayrollAdminOfficerTaxDocsGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/officer/tax_docs',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Officer Paystubs Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminOfficerPaystubsLinkApiV1PayrollAdminOfficerPaystubsGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/officer/paystubs',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Officer Post Tax Deductions Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminOfficerPostTaxDeductionsLinkApiV1PayrollAdminOfficerPostTaxDeductionsGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/officer/post_tax_deductions',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Officer Benefits Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminOfficerBenefitsLinkApiV1PayrollAdminOfficerBenefitsGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/officer/benefits',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Admin Employee Payment Setup Link
     * @param officerId
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollAdminEmployeePaymentSetupLinkApiV1PayrollAdminEmployeePaymentSetupGet(
        officerId: string,
    ): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/employee/payment_setup',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Employee Tax Parameters
     * @param officerId
     * @returns BelfryAPIEmployeeTaxParameterResponse Successful Response
     * @throws ApiError
     */
    public static payrollEmployeeTaxParametersApiV1PayrollAdminEmployeeTaxParametersGet(
        officerId: string,
    ): CancelablePromise<BelfryAPIEmployeeTaxParameterResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/admin/employee/tax_parameters',
            query: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Officer Tax Docs Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollOfficerTaxDocsLinkApiV1PayrollOfficerTaxDocsPost(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officer/tax_docs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Employee Paystubs Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollEmployeePaystubsLinkApiV1PayrollEmployeePaystubsPost(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/employee/paystubs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Officer Onboard Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollOfficerOnboardLinkApiV1PayrollOfficerOnboardGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/officer/onboard',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Officer Tax Docs Email Link
     * @returns ResultResponse Successful Response
     * @throws ApiError
     */
    public static payrollOfficerTaxDocsEmailLinkApiV1PayrollOfficerEmailTaxDocsPost(): CancelablePromise<ResultResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officer_email/tax_docs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Employee Paystubs Email Link
     * @returns ResultResponse Successful Response
     * @throws ApiError
     */
    public static payrollEmployeePaystubsEmailLinkApiV1PayrollEmployeeEmailPaystubsPost(): CancelablePromise<ResultResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/employee_email/paystubs',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Officer Onboard Email Link
     * @returns ResultResponse Successful Response
     * @throws ApiError
     */
    public static payrollOfficerOnboardEmailLinkApiV1PayrollOfficerEmailOnboardPost(): CancelablePromise<ResultResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officer_email/onboard',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Bulk Officer Onboard Link
     * @param requestBody
     * @returns ResultResponse Successful Response
     * @throws ApiError
     */
    public static sendBulkOfficerOnboardLinkApiV1PayrollOfficersBulkOnboardLinkPost(
        requestBody: Array<string>,
    ): CancelablePromise<ResultResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/payroll/officers_bulk/onboard_link',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Payroll Checks
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadPayrollChecksApiV1PayrollPayrollIdChecksGet(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/{payroll_id}/checks',
            path: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Simplyinsured Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollCompanySimplyinsuredLinkApiV1PayrollCompanySimplyinsuredGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/simplyinsured',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company Guideline401K Link
     * @returns UrlResponse Successful Response
     * @throws ApiError
     */
    public static payrollCompanyGuideline401KLinkApiV1PayrollCompanyGuideline401KGet(): CancelablePromise<UrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/guideline401k',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payroll Company W2 Preview
     * @returns any Successful Response
     * @throws ApiError
     */
    public static payrollCompanyW2PreviewApiV1PayrollCompanyW2PreviewGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/w2_preview',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Company Payroll Cash Req Report Csv
     * @param payrollId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static companyPayrollCashReqReportCsvApiV1PayrollCompanyCashReqReportCsvGet(
        payrollId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/cash_req_report_csv',
            query: {
                'payroll_id': payrollId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Pdf Workers Comp Report
     * Return the PDF content of workers comp report data.
     * @param startDate
     * @param endDate
     * @param isSummary
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportPdfWorkersCompReportApiV1PayrollCompanyWorkersCompReportPdfStartDateEndDateIsSummaryIsSummaryGet(
        startDate: string,
        endDate: string,
        isSummary: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/workers_comp_report/pdf/{start_date}/{end_date}/is_summary={is_summary}',
            path: {
                'start_date': startDate,
                'end_date': endDate,
                'is_summary': isSummary,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export Csv Workers Comp Report Itemized
     * Return the CSV content of workers comp report data.
     *
     * If is_summary is True, the CSV is summarized with just total wages and total hours per WC code.
     * @param startDate
     * @param endDate
     * @param isSummary
     * @returns any Successful Response
     * @throws ApiError
     */
    public static exportCsvWorkersCompReportItemizedApiV1PayrollCompanyWorkersCompReportCsvStartDateEndDateIsSummaryIsSummaryGet(
        startDate: string,
        endDate: string,
        isSummary: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/payroll/company/workers_comp_report/csv/{start_date}/{end_date}/is_summary={is_summary}',
            path: {
                'start_date': startDate,
                'end_date': endDate,
                'is_summary': isSummary,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
