import { createTheme } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'
const softerWhite = '#FEFEFE'
const neutralWhite = '#FCFCFC'
const primaryBlue = '#253A8E'
const secondaryBlue = '#4338CA'
const serendipitousPink = '#C9347A'
const hoverPink = '#b42e6d'
const darkInactive = '#9CA3AF'
const navBlue = '#253A8E'

// radius
const xlargeRadius = 8
const radius = 5

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        primarySoft: PaletteColorOptions
        accent: PaletteColorOptions
        nav: Palette['primary']
    }
    interface PaletteOptions {
        primarySoft: PaletteColorOptions
        accent: PaletteColorOptions
        nav: PaletteColorOptions
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        accent: true
    }
    interface ButtonPropsSizeOverrides {
        xlarge: true
    }
}

declare module '@mui/material/Radio' {
    interface RadioPropsColorOverrides {
        accent: true
    }
}

const newBelfryTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: primaryBlue,
        },
        primarySoft: {},
        secondary: {
            main: secondaryBlue,
        },
        accent: {
            main: serendipitousPink,
        },
        nav: {
            main: navBlue,
            contrastText: softerWhite,
        },
    },
    typography: { fontFamily: 'Inter, Manrope' },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'h1' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '96px',
                    },
                },
                {
                    props: { variant: 'h2' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '72px',
                    },
                },
                {
                    props: { variant: 'h3' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '48px',
                    },
                },
                {
                    props: { variant: 'h4' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '34px',
                    },
                },
                {
                    props: { variant: 'h5' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '24px',
                    },
                },
                {
                    props: { variant: 'h6' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '20px',
                    },
                },
                {
                    props: { variant: 'subtitle1' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '16px',
                    },
                },
                {
                    props: { variant: 'subtitle2' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '14px',
                    },
                },
                {
                    props: { variant: 'body1' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '16px',
                    },
                },
                {
                    props: { variant: 'body2' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '14px',
                    },
                },
                {
                    props: { variant: 'caption' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'overline' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '12px',
                    },
                },
            ],
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                    '& .MuiDataGrid-footerContainer': {
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: darkInactive,
                        borderBottomRightRadius: xlargeRadius,
                        borderBottomLeftRadius: xlargeRadius,
                        borderTop: 'none',
                        alignContent: 'center',
                    },
                    '& .MuiDataGrid-main': {
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: darkInactive,
                        borderTopRightRadius: xlargeRadius,
                        borderTopLeftRadius: xlargeRadius,
                        borderBottom: 'none',
                        marginTop: '20px',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        minHeight: '56px',
                        paddingLeft: '24px',
                        '& .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                    },
                    '& .MuiDataGrid-cell': {
                        paddingLeft: '24px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    paddingBottom: 0,
                    paddingTop: 0,
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: { size: 'xlarge' },
                    style: {
                        fontSize: 16,
                        fontWeight: 500,
                        paddingLeft: 28,
                        paddingRight: 28,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: radius,
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        fontSize: 15,
                        fontWeight: 500,
                        paddingLeft: 22,
                        paddingRight: 22,
                        paddingTop: 8,
                        paddingBottom: 8,
                        borderRadius: radius,
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        fontSize: 14,
                        fontWeight: 500,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 6,
                        paddingBottom: 6,
                        borderRadius: radius,
                    },
                },
                {
                    props: { size: 'small' },
                    style: {
                        fontSize: 13,
                        fontWeight: 500,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 4,
                        paddingBottom: 4,
                        borderRadius: radius,
                    },
                },
                {
                    props: { variant: 'outlined', color: 'accent' },
                    style: {
                        backgroundColor: neutralWhite,
                    },
                },
                {
                    props: { variant: 'contained', color: 'accent' },
                    style: {
                        color: neutralWhite,
                        '&:hover': {
                            // guess
                            backgroundColor: hoverPink,
                        },
                    },
                },
            ],
        },
        // inspired by https://mui.com/material-ui/react-switch/ IOSSwitch. We can throw away since this is kind of dumb
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 40,
                    height: 24,
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                        padding: 0,
                        margin: 2,
                        transitionDuration: '300ms',
                        '&.Mui-checked': {
                            transform: 'translateX(16px)',
                            color: '#fff',
                            '& + .MuiSwitch-track': {
                                backgroundColor: '#253A8E',
                                opacity: 1,
                                border: 0,
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.5,
                            },
                        },
                        '&.Mui-focusVisible .MuiSwitch-thumb': {
                            color: '#33cf4d',
                            border: '6px solid #fff',
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.7,
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        width: 20,
                        height: 20,
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 26 / 2,
                        backgroundColor: '#E9E9EA',
                        opacity: 1,
                        transition: {
                            'background-color': {
                                duration: 500,
                            },
                        },
                    },
                },
            },
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        width: 36,
                        height: 20,
                        '& .MuiSwitch-thumb': {
                            boxSizing: 'border-box',
                            width: 15,
                            height: 15,
                        },
                    },
                },
            ],
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 'inherit',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    color: primaryBlue,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: 0,
                },
            },
        },
    },
})

export default newBelfryTheme
