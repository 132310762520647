import React from 'react'

import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useRouter } from 'next/router'
import { validateCustomerId } from '../../services/customer'
import { useLoadParentCustomer } from '../../services/parent_customers'
import { DetailTabs } from '../DetailTabs'
import { Stack } from '@mui/material'
import { Breadcrumb } from '../Breadcrumb'

import { DetailsTab } from '../../types/detailsTab'
import { withRole } from '../withRole'
import { ParentCustomer } from '../../generated'

type LayoutProps = React.PropsWithChildren<{}>
export const TabbedParentCustomerLayoutAdmin = ({ children }: LayoutProps) => {
    const router = useRouter()
    const id = validateCustomerId(router.query)
    const { parentCustomer } = useLoadParentCustomer(id)

    const tabLinks: DetailsTab[] = [
        {
            name: 'Details',
            link: `/customers/parents/${id}/details`,
            linkRoot: `/customers/parents/${id}/details`,
        },
        {
            name: 'Billing Settings',
            link: `/customers/parents/${id}/billing`,
            linkRoot: `/customers/parents/${id}/billing`,
        },
    ]

    return (
        <ParentCustomerLayoutGeneral
            parentCustomer={parentCustomer}
            id={id}
            tabLinks={tabLinks}
        >
            {children}
        </ParentCustomerLayoutGeneral>
    )
}
export const TabbedParentCustomerLayoutBasic = ({ children }: LayoutProps) => {
    const router = useRouter()
    const id = validateCustomerId(router.query)
    const { parentCustomer } = useLoadParentCustomer(id)

    let tabLinks: DetailsTab[] = [
        {
            name: 'Details',
            link: `/customers/parents/${id}/details`,
            linkRoot: `/customers/parents/${id}/details`,
        },
    ]

    return (
        <ParentCustomerLayoutGeneral
            parentCustomer={parentCustomer}
            id={id}
            tabLinks={tabLinks}
        >
            {children}
        </ParentCustomerLayoutGeneral>
    )
}

const ParentCustomerLayoutGeneral = ({
    parentCustomer,
    tabLinks,
    id,
    children,
}: {
    parentCustomer?: ParentCustomer
    tabLinks: DetailsTab[]
    id: string
    children: React.ReactNode
}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ padding: 1 }}>
                <Breadcrumb path={'/customers/parents'} label={'All Parents'} />
            </Box>
            <Box sx={{ padding: 1 }}>
                <Grid container direction="row" spacing={1}>
                    <Grid item flexGrow={5}>
                        {parentCustomer && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                            >
                                <Typography variant="h2">
                                    {parentCustomer?.name}
                                </Typography>
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <DetailTabs data={tabLinks} />
            <main>{children}</main>
        </Box>
    )
}

export const TabbedParentCustomerLayout = withRole(['Admin'])(
    TabbedParentCustomerLayoutAdmin,
    TabbedParentCustomerLayoutBasic
)
