/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FirmAddon = {
    addon_kind: FirmAddon.addon_kind;
    firm_id: string;
    id: string;
};

export namespace FirmAddon {

    export enum addon_kind {
        CHECKHQ_PAYROLL = 'CHECKHQ_PAYROLL',
        EMPLOYEE_ONBOARDING = 'EMPLOYEE_ONBOARDING',
        PAYMENT_ACCEPTANCE = 'PAYMENT_ACCEPTANCE',
        PATROLS_FEAT = 'PATROLS_FEAT',
        BENEFITS_ADMIN = 'BENEFITS_ADMIN',
    }


}

