/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Holiday = {
    pd_hol_name?: Holiday.pd_hol_name;
    custom_date?: string;
    custom_name?: string;
    contract_id: string;
    is_paid?: boolean;
    is_billed?: boolean;
    id?: string;
    next_occurrence?: string;
};

export namespace Holiday {

    export enum pd_hol_name {
        NEW_YEAR_S_DAY = 'New Year\'s Day',
        BIRTHDAY_OF_MARTIN_LUTHER_KING_JR_ = 'Birthday of Martin Luther King, Jr.',
        WASHINGTON_S_BIRTHDAY = 'Washington’s Birthday',
        MEMORIAL_DAY = 'Memorial Day',
        JUNETEENTH_NATIONAL_INDEPENDENCE_DAY = 'Juneteenth National Independence Day',
        INDEPENDENCE_DAY = 'Independence Day',
        LABOR_DAY = 'Labor Day',
        COLUMBUS_DAY = 'Columbus Day',
        VETERANS_DAY = 'Veterans Day',
        THANKSGIVING_DAY = 'Thanksgiving Day',
        CHRISTMAS_DAY = 'Christmas Day',
    }


}

