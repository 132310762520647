import { useMemo } from 'react'
import { FEATURE_GATE, Features } from '../constants/previewEligibleOrgs'
import { useOrgOfUser } from '../utils/auth'

export const usePreviewEligible = (feature: Features) => {
    const orgId = useOrgOfUser()

    const eligible = useMemo<boolean>(() => {
        return (orgId && FEATURE_GATE[feature].includes(orgId)) || false
    }, [orgId, feature])

    return eligible
}
