import React from 'react'
import { Box, Grid } from '@mui/material'
import { DetailTabs } from '../DetailTabs'
import { DetailsTab } from '../../types/detailsTab'
type LayoutProps = React.PropsWithChildren<{}>

export const TabbedAnalyticsOperationsLayout = ({ children }: LayoutProps) => {
    let tabLinks: DetailsTab[] = [
        {
            name: 'Incidents',
            link: `/analytics/operations/incidents`,
            linkRoot: `/analytics/operations/incidents`,
        },
        {
            name: 'Shift Times',
            link: `/analytics/operations/shift_times`,
            linkRoot: `/analytics/operations/shift_times`,
        },
        {
            name: 'Officer Punches',
            link: `/analytics/operations/punches`,
            linkRoot: `/analytics/operations/punches`,
        },
        {
            name: 'Tour Performance',
            link: `/analytics/operations/tours`,
            linkRoot: `/analytics/operations/tours`,
        },
        {
            name: 'Break Compliance',
            link: `/analytics/operations/breaks`,
            linkRoot: `/analytics/operations/breaks`,
        },
    ]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container>
                <Grid item xs={12}>
                    <DetailTabs data={tabLinks} variant={'fullWidth'} />
                </Grid>
            </Grid>
            <main>{children}</main>
        </Box>
    )
}
