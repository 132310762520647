/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityReportFiled } from '../models/ActivityReportFiled';
import type { ActivityUnion } from '../models/ActivityUnion';
import type { BasicCustomer } from '../models/BasicCustomer';
import type { BelltowerReportType } from '../models/BelltowerReportType';
import type { ConsolidatedActivityReport } from '../models/ConsolidatedActivityReport';
import type { CustomReport } from '../models/CustomReport';
import type { FirmInfoForClientPortal } from '../models/FirmInfoForClientPortal';
import type { Incident } from '../models/Incident';
import type { Notice } from '../models/Notice';
import type { NoticeCreate } from '../models/NoticeCreate';
import type { Page_ActivityUnion_ } from '../models/Page_ActivityUnion_';
import type { Page_HighLevelActivity_ } from '../models/Page_HighLevelActivity_';
import type { PaginatedActivityFilter } from '../models/PaginatedActivityFilter';
import type { ReportFilterParams } from '../models/ReportFilterParams';
import type { ReportResponsePage } from '../models/ReportResponsePage';
import type { ScheduleShiftInstance } from '../models/ScheduleShiftInstance';
import type { ShiftInstanceForClientBelltower } from '../models/ShiftInstanceForClientBelltower';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClientPortalService {

    /**
     * Get Firm Info
     * @returns FirmInfoForClientPortal Successful Response
     * @throws ApiError
     */
    public static getFirmInfoApiV1ClientPortalFirmInfoGet(): CancelablePromise<FirmInfoForClientPortal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/firm_info',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Client Customers
     * @returns BasicCustomer Successful Response
     * @throws ApiError
     */
    public static getClientCustomersApiV1ClientPortalCustomersGet(): CancelablePromise<Array<BasicCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/customers',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Client Schedule For Date Range
     * @param startDate
     * @param endDate
     * @returns ScheduleShiftInstance Successful Response
     * @throws ApiError
     */
    public static getClientScheduleForDateRangeApiV1ClientPortalScheduleGet(
        startDate: string,
        endDate: string,
    ): CancelablePromise<Array<ScheduleShiftInstance>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/schedule',
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Live Belltower
     * @returns ShiftInstanceForClientBelltower Successful Response
     * @throws ApiError
     */
    public static getLiveBelltowerApiV1ClientPortalBelltowerLiveGet(): CancelablePromise<Array<ShiftInstanceForClientBelltower>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/belltower/live',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Report Types
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getReportTypesApiV1ClientPortalBelltowerReportTypesGet(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/belltower-report-types',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Belltower Reports
     * Return the belltower reports for a client.
     * @param page
     * @param pageSize
     * @param requestBody
     * @returns ReportResponsePage Successful Response
     * @throws ApiError
     */
    public static readBelltowerReportsApiV1ClientPortalBelltowerReportsPost(
        page: number,
        pageSize: number,
        requestBody: (ReportFilterParams | null),
    ): CancelablePromise<ReportResponsePage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/client_portal/belltower/reports',
            query: {
                'page': page,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Belltower Report For Customer Type And Id
     * Return the belltower report
     * @param customerId
     * @param id
     * @param type
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readBelltowerReportForCustomerTypeAndIdApiV1ClientPortalBelltowerReportsCustomerIdIdGet(
        customerId: string,
        id: string,
        type: (BelltowerReportType | string),
    ): CancelablePromise<(CustomReport | ConsolidatedActivityReport | Incident | ActivityReportFiled)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/belltower/reports/{customer_id}/{id}',
            path: {
                'customer_id': customerId,
                'id': id,
            },
            query: {
                'type': type,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pdf For Belltower Report
     * Return the belltower reports for a customer, report type and bell tower id.
     * @param customerId
     * @param id
     * @param type
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createPdfForBelltowerReportApiV1ClientPortalBelltowerPdfReportsCustomerIdIdPost(
        customerId: string,
        id: string,
        type: (BelltowerReportType | string),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/client_portal/belltower/pdf-reports/{customer_id}/{id}',
            path: {
                'customer_id': customerId,
                'id': id,
            },
            query: {
                'type': type,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Activities For Consolidated Activity Report
     * @param carId
     * @param customerId
     * @param page Page number
     * @param size Page size
     * @returns Page_ActivityUnion_ Successful Response
     * @throws ApiError
     */
    public static readActivitiesForConsolidatedActivityReportApiV1ClientPortalBelltowerReportsCustomerIdConsolidatedActivityReportCarIdActivitiesGet(
        carId: string,
        customerId: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_ActivityUnion_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/belltower/reports/{customer_id}/consolidated-activity-report/{car_id}/activities/',
            path: {
                'car_id': carId,
                'customer_id': customerId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Activities For Client
     * Return the activities for a client.
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_HighLevelActivity_ Successful Response
     * @throws ApiError
     */
    public static readActivitiesForClientApiV1ClientPortalBelltowerActivitiesPost(
        page: number = 1,
        size: number = 50,
        requestBody?: (PaginatedActivityFilter | null),
    ): CancelablePromise<Page_HighLevelActivity_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/client_portal/belltower/activities',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Activity By Id
     * Return the activity given an id
     * @param id
     * @returns ActivityUnion Successful Response
     * @throws ApiError
     */
    public static readActivityByIdApiV1ClientPortalActivitiesIdGet(
        id: string,
    ): CancelablePromise<ActivityUnion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/activities/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Notices
     * @returns Notice Successful Response
     * @throws ApiError
     */
    public static getNoticesApiV1ClientPortalNoticeGet(): CancelablePromise<Array<Notice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/client_portal/notice',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Notice
     * @param customerId
     * @param requestBody
     * @returns Notice Successful Response
     * @throws ApiError
     */
    public static createNoticeApiV1ClientPortalNoticePut(
        customerId: string,
        requestBody: NoticeCreate,
    ): CancelablePromise<Notice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/client_portal/notice',
            query: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Notice
     * @param noticeId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteNoticeApiV1ClientPortalNoticeNoticeIdDelete(
        noticeId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/client_portal/notice/{notice_id}',
            path: {
                'notice_id': noticeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
