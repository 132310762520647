import React from 'react'

import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useRouter } from 'next/router'
import { useLoadCustomer, validateCustomerId } from '../../services/customer'
import { DetailTabs } from '../DetailTabs'
import { Stack, Chip } from '@mui/material'
import { useRolesOfUser, useUserIsAdmin } from '../../utils/auth'
import { Breadcrumb } from '../Breadcrumb'

import { DetailsTab } from '../../types/detailsTab'
import { withRole } from '../withRole'
import { Customer } from '../../generated'
import { CustomerActionsButton } from '../CustomerActionsMenu'

const AUDIT_LOG_ENABLED_ROLES = ['Admin']

type LayoutProps = React.PropsWithChildren<{}>
type TabbedCustomerLayoutProps = {
    hideMenuTabs?: boolean
} & LayoutProps
export const TabbedCustomerLayoutAdmin = ({
    hideMenuTabs = false,
    children,
}: TabbedCustomerLayoutProps) => {
    const router = useRouter()
    const id = validateCustomerId(router.query)
    const { customer } = useLoadCustomer(id)
    const type = customer?.type
    const userRoles = useRolesOfUser()
    const isAuditLogPrivileged = useUserIsAdmin()

    const tabLinks: DetailsTab[] = [
        {
            name: 'Details',
            link: `/customers/${id}/details`,
            linkRoot: `/customers/${id}/details`,
        },
        {
            name: 'Schedule',
            link: `/customers/${id}/schedule`,
            linkRoot: `/customers/${id}/schedule`,
        },
        {
            name: 'Site & Billing Settings',
            link: `/customers/${id}/billing`,
            linkRoot: `/customers/${id}/billing`,
        },
        {
            name: 'Bell Tower',
            link: `/customers/${id}/belltower/activity`,
            linkRoot: `/customers/${id}/belltower`,
        },
        {
            name: 'Documents',
            link: `/customers/${id}/documents`,
            linkRoot: `/customers/${id}/documents`,
        },
    ]

    return (
        <CustomerLayoutGeneral
            customer={customer}
            id={id}
            tabLinks={tabLinks}
            hideMenuTabs={hideMenuTabs}
            isAuditLogPrivileged={isAuditLogPrivileged}
        >
            {children}
        </CustomerLayoutGeneral>
    )
}
export const TabbedCustomerLayoutBasic = ({
    hideMenuTabs = false,
    children,
}: TabbedCustomerLayoutProps) => {
    const router = useRouter()
    const id = validateCustomerId(router.query)
    const { customer } = useLoadCustomer(id)
    const userRoles = useRolesOfUser()
    const isAuditLogPrivileged = useUserIsAdmin()

    const tabLinks: DetailsTab[] = [
        {
            name: 'Details',
            link: `/customers/${id}/details`,
            linkRoot: `/customers/${id}/details`,
        },
        {
            name: 'Schedule',
            link: `/customers/${id}/schedule`,
            linkRoot: `/customers/${id}/schedule`,
        },
        {
            name: 'Bell Tower',
            link: `/customers/${id}/belltower/activity`,
            linkRoot: `/customers/${id}/belltower`,
        },
        {
            name: 'Documents',
            link: `/customers/${id}/documents`,
            linkRoot: `/customers/${id}/documents`,
        },
    ]

    return (
        <CustomerLayoutGeneral
            customer={customer}
            id={id}
            tabLinks={tabLinks}
            hideMenuTabs={hideMenuTabs}
            isAuditLogPrivileged={isAuditLogPrivileged}
        >
            {children}
        </CustomerLayoutGeneral>
    )
}

const CustomerLayoutGeneral = ({
    customer,
    tabLinks,
    id,
    children,
    hideMenuTabs,
    isAuditLogPrivileged,
}: {
    customer?: Customer
    tabLinks: DetailsTab[]
    id: string
    children: React.ReactNode
    hideMenuTabs: boolean
    isAuditLogPrivileged: boolean
}) => {
    const router = useRouter()
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ padding: 1 }}>
                {hideMenuTabs ? (
                    <Breadcrumb
                        path={`/customers/${id}/details`}
                        label={'Site Details'}
                    />
                ) : (
                    <Breadcrumb path={'/customers/sites'} label={'All Sites'} />
                )}
            </Box>
            <Box sx={{ padding: 1 }}>
                <Grid container direction="row" spacing={1}>
                    <Grid item flexGrow={5}>
                        {customer && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                            >
                                <Typography variant="h2">
                                    {customer?.name}
                                </Typography>
                                {customer?.is_archived && (
                                    <Chip label={'Archived'} />
                                )}
                            </Stack>
                        )}
                    </Grid>
                    {router.asPath === `/customers/${id}/details` && (
                        <Grid item xs={3}>
                            <CustomerActionsButton
                                customer={customer}
                                id={id}
                                isAuditLogPrivileged={isAuditLogPrivileged}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            {hideMenuTabs ? null : <DetailTabs data={tabLinks} />}
            <main>{children}</main>
        </Box>
    )
}

export const TabbedCustomerLayout = withRole(['Admin'])(
    TabbedCustomerLayoutAdmin,
    TabbedCustomerLayoutBasic
)
