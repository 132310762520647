import React from 'react'
import { Officer } from '../../generated'
import { Button, Grid, Typography, ButtonGroup } from '@mui/material'
import { restoreOfficer } from '../../services/officer'
import { Loading } from '../Loading'
import { useSWRConfig } from 'swr'
import { ApiError } from '../../generated'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { ContentModal } from '../Modal'

type RestoreModalProps = {
    open: boolean
    handleClose: () => void
    officerName: string | undefined
    officerId: string
}

export const RestoreModal = ({
    open,
    handleClose,
    officerName,
    officerId,
}: RestoreModalProps) => {
    const { mutate } = useSWRConfig()
    const router = useRouter()
    const [submitting, isSubmitted] = React.useState<boolean>(false)
    const restoreOfficerCallback = React.useCallback(() => {
        isSubmitted(true)
        return restoreOfficer(officerId, mutate)
            .then((value: any) => {
                isSubmitted(false)
                handleClose()
            })
            .catch((error: ApiError) => {
                toast.error(error?.message)
                isSubmitted(false)
            })
    }, [])

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            {submitting && <Loading style={{ minHeight: undefined }} />}
            {!submitting && (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h2">Restore Worker</Typography>
                        <Typography variant="body1">
                            This will restore {officerName} to no longer be
                            terminated.
                        </Typography>
                    </Grid>
                    <Grid item marginTop={3}>
                        <ButtonGroup fullWidth>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => restoreOfficerCallback()}
                            >
                                Restore worker
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )}
        </ContentModal>
    )
}

type OfficerRestoreButtonProps = {
    officer: Officer | undefined
    id: string
    isTerminationAndPayrollPrivileged: boolean
}
export function OfficerRestoreButton({
    officer,
    id,
    isTerminationAndPayrollPrivileged,
}: OfficerRestoreButtonProps) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        <>
            <RestoreModal
                open={open}
                handleClose={handleClose}
                officerName={officer?.name}
                officerId={id}
            />
            <Button
                variant="text"
                color="primary"
                onClick={handleOpen}
                disabled={!isTerminationAndPayrollPrivileged}
                fullWidth
            >
                Restore Worker
            </Button>
        </>
    )
}
