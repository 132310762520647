import React from 'react'
import { Officer, OfficerCategory } from '../../../generated'
import { Button, Alert, AlertTitle } from '@mui/material'

import { withRole } from '../../withRole'
import { useSWRConfig } from 'swr'
import { enableOfficerPayroll } from '../../../services/officer'

const EnableOfficerPayrollButton = ({ officer }: { officer: Officer }) => {
    const { mutate } = useSWRConfig()
    return (
        <Button
            variant="contained"
            color="warning"
            onClick={() => {
                if (
                    confirm(
                        `Are you sure you want to enable payroll for ${officer.name}`
                    )
                ) {
                    enableOfficerPayroll(officer.id, mutate)
                }
            }}
        >
            Enable payroll
        </Button>
    )
}
const RoleGatedEnableOfficerPayrollButton = withRole(['Admin', 'HR'])(
    EnableOfficerPayrollButton
)

export function MaybeOfficerPayrollDisabledComp({
    officer,
}: {
    officer?: Officer
}) {
    if (!officer) {
        return null
    }
    return officer.is_payroll_disabled ? (
        <Alert color="warning">
            <AlertTitle>Payroll Disabled for {officer.name}</AlertTitle>This
            worker will not be included in Belfry&apos;s integrated payroll.{' '}
            <RoleGatedEnableOfficerPayrollButton officer={officer} />
        </Alert>
    ) : null
}

export function getOfficerCatLabel(officerCategory: OfficerCategory): string {
    if (officerCategory === OfficerCategory.CONTRACTOR_1099_) {
        return officerCategory
    } else {
        return `${officerCategory} Employee (W2)`
    }
}
